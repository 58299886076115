import ImageMapper from 'react-img-mapper';
// import BgImg from "../img/NewHome/DeltaHomeNew.png";
import BgImg from "../img/NewHome/DeltaHomeNew_Shelf.png";
import { useEffect , useState } from 'react';

const HomePageImageMapper = ({ showFeedback , showDocDepo , showHelpVideos , handleShowBooks , carouselPositionChanged , updateImageMapperLoaded }) => {
  const [windowSizing , setWindowSizing] = useState({height : (window.innerHeight) - 60 , width : window.innerWidth})
  
  useEffect(()=>{
    console.log("windowSizing",windowSizing)
      },[windowSizing])

  const [areaCoords, setAreaCords] = useState(
    [
      {
        "id": "469f9800-c45a-483f-b13e-bd24f3fb79f4",
        "title": "Feedback",
        "shape": "poly",
        "name": "feedback",
        // "fillColor": "#eab54d4d",
        // "strokeColor": "black",
        "fillColor": "transparent",
        "strokeColor": "transparent",
        // "fillColor": "#eab54d4d",
        // "strokeColor": "black",
        "coords": [
          199,907, 400,879, 466,935, 472,1143, 260,1184, 202,1133
        ],
        "xCoords" : [199 , 400 , 466, 472, 260, 202],
        "yCoords" : [907 , 879, 935, 1143 , 1184 , 1133]
      },
      {
        "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7ab7",
        "title": "ContributeKnowledge",
        "shape": "poly",
        "name": "contributeknowledge",
        "fillColor": "transparent",
        "strokeColor": "transparent",
        // "fillColor": "#eab54d4d",
        // "strokeColor": "black",
        "coords": [
          502,644, 655,644, 673,670, 894,675, 922,713, 935,1031, 896,1092, 510,1092
        ],
        "xCoords" : [502,655,673,894,922,935, 896,510],
        "yCoords" : [644, 644, 670, 675,713,1031, 1092, 1092 ]
      },
      {
        "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7789",
        "title": "Sol360",
        "shape": "poly",
        "name": "sol360",
        "fillColor": "transparent",
        "strokeColor": "transparent",
        // "fillColor": "#eab54d4d",
        // "strokeColor": "black",
        "coords": [
          1057,614, 1113,596, 1118,720, 1169,720, 1169,919, 1209,924, 1204,1076, 985,1079, 985,960, 1016,921, 1013,731, 1067,715
        ],
        xCoords : [1057, 1113 , 1118 , 1169, 1169, 1209 , 1204, 985, 985, 1016, 1013, 1067],
        yCoords : [614, 596, 720, 720, 919, 924, 1076, 1079, 960, 921, 731, 715]
      },
      {
        "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7749",
        "title": "HelpVideos",
        "shape": "poly",
        "name": "helpvideos",
        "fillColor": "transparent",
        "strokeColor": "transparent",
        // "fillColor": "#eab54d4d",
        // "strokeColor": "black",
        "coords": [
          2003,1041, 2051,972, 2128,916, 2204,995, 2235,1071, 2184,1160, 1995,1148
        ],
        xCoords : [2003, 2051, 2128 , 2204, 2235, 2184, 1995],
        yCoords: [1041, 972, 916, 995, 1071, 1160, 1148]
      },
      {
        "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7759",
        "title": "DeltaBook",
        "shape": "poly",
        "name": "deltabook",
        "fillColor": "transparent",
        "strokeColor": "transparent",
        // "fillColor": "#eab54d4d",
        // "strokeColor": "black",
        "coords": [
          2181,228, 2344,228, 2344,444, 2181,444
        ],
        xCoords : [2181, 2344 , 2344, 2181],
        yCoords : [228, 228 , 444 , 444]
      },
      {
        "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7239",
        "title": "SOWBook",
        "shape": "poly",
        "name": "sol360book",
        "fillColor": "transparent",
        "strokeColor": "transparent",
        // "fillColor": "#eab54d4d",
        // "strokeColor": "black",
        "coords": [
          2375,228,2537,228,2537,444,2375,444
        ],
        xCoords : [2375, 2537, 2537 , 2375],
        yCoords: [228 , 228, 444, 444]
      },
      {
        "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7232",
        "title": "MindSparksBook",
        "shape": "poly",
        "name": "mindsparksbook",
        "fillColor": "transparent",
        "strokeColor": "transparent",
        // "fillColor": "#eab54d4d",
        // "strokeColor": "black",
        "coords": [
          2565,235, 2728,232, 2731,446, 2568,446
        ],
        xCoords : [2565, 2728, 2731, 2568],
        yCoords : [235, 232, 446, 446]
      },
      {
        "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7230",
        "title": "A&PSBook",
        "shape": "poly",
        "name": "a&psbook",
        "fillColor": "transparent",
        "strokeColor": "transparent",
        // "fillColor": "#eab54d4d",
        // "strokeColor": "black",
        "coords": [
          2156,513,2331,513,2334,714,2156,719
        ],
        xCoords : [2156,2331, 2334, 2156],
        yCoords : [513, 513, 714, 719]
      },
      {
        "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7231",
        "title": "AccBook",
        "shape": "poly",
        "name": "accbook",
        "fillColor": "transparent",
        "strokeColor": "transparent",
        // "fillColor": "#eab54d4d",
        // "strokeColor": "black",
        "coords": [
          2362,510,2542,508,2548,714,2364,719
        ],
        xCoords : [2362, 2542,2548,2364],
        yCoords: [510,508,714,719]
      },
      {
        "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7235",
        "title": "ChatBotBook",
        "shape": "poly",
        "name": "chatbotbook",
        "fillColor": "transparent",
        "strokeColor": "transparent",
        // "fillColor": "#eab54d4d",
        // "strokeColor": "black",
        "coords": [
          2573,517,2746,512,2751,720,2573,720
        ],
        xCoords : [2573,2746,2751,2573],
        yCoords : [517,512,720,720]
      },
      {
        "id": "469f9800-c45a-483f-b13e-bd24f3fb29f4",
        "title": "NewsCarousel",
        "shape": "poly",
        "name": "newscarousel",
        "fillColor": "transparent",
        "strokeColor": "transparent",
        // "fillColor": "#eab54d4d",
        // "strokeColor": "black",
        "coords": [
          1321,766, 1726,766, 1726,1015, 1321,1015
        ],
        xCoords : [1321, 1726, 1726, 1321],
        yCoords: [766, 766 , 1015, 1015]
      },
      ]
  )


  // width - 410 (1725 - 1315) 2 - 0
  // height - 240 (955 - 715) 5 - 1

  const autoResize =() =>{
    setWindowSizing({height : (window.innerHeight) - 10 , width : window.innerWidth})
    const ratio = window.innerWidth / 2880;
    const heightRatio = window.innerHeight  / 1396;
    
    let existingCoords = [...areaCoords];

    let finalCords = [];
    for (const area of existingCoords) {
      const newCoords = [];
      const newXCoords = [];
      const newYCoords = [];

      for(const i in area.xCoords) {
        newXCoords.push(Math.round(area.xCoords[i] * ratio))
        newYCoords.push(Math.round(area.yCoords[i] * heightRatio))
        newCoords.push(Math.round(area.xCoords[i] * ratio))
        newCoords.push(Math.round(area.yCoords[i] * heightRatio))
      }

      // for (const originalCoord of area.coords) {
      //     newCoords.push(Math.round(originalCoord * ratio));
      // }
      // finalCords.push({
      //   ...area,
      //   coords : area.name === "feedback" ? [72 , 314,   143, 306,  164, 320,  167, 389,   94, 402,   73, 385]  : newCoords
      // })

      finalCords.push({
        ...area,
        yCoords : newYCoords,
        coords : newCoords,
        xCoords : newXCoords,
      })



      if(area.name === "newscarousel") {
        let carouselCoords = {
          left : (newCoords[0]).toString() + "px",
          top : (newCoords[1] + 60).toString() + "px",
          height : (newCoords[5] - newCoords[1]).toString() + "px",
          width : (newCoords[2] - newCoords[0]).toString() + "px"
        };
        carouselPositionChanged(carouselCoords)
      }

    }

    setAreaCords(finalCords);
  }

  useEffect(() => {

    autoResize();
    window.addEventListener('resize', autoResize);
    

    // Return a function to disconnect the event listener
    return () => window.removeEventListener('resize', autoResize);
  }, []);

  const imageClicked = (event) => {
    switch (event.name) {
      case "feedback":
        showFeedback()
        break;
      case "contributeknowledge" :
        console.log("imageClicked - contributeknowledge")
        showDocDepo();
        break;
      case "sol360" :
        var url = (window.location.href.indexOf("delta.app.hpecorp.net")>-1) ? "https://sol360-ui.azurewebsites.net" : "https://sol360-ui-stage.azurewebsites.net"
        window.open(url + '/sow' + (localStorage.getItem("UserDetailsBase64") ? "?u=" + localStorage.getItem("UserDetailsBase64") : ""), "_blank")
        break;
      case "helpvideos":
        showHelpVideos();
        break;
      default :
        handleShowBooks(event.name)
        break;
    }
  }

  const imageMapperLoaded = (e) => {
    updateImageMapperLoaded();
  }
  
  return (
    <>
      <ImageMapper width={windowSizing.width} height={windowSizing.height} imgWidth={windowSizing.width} src={BgImg} map={{
        name: 'my-map',
        areas: areaCoords,
      }} 
      onClick={(e) => imageClicked(e)}
      onLoad={(e) => imageMapperLoaded(e)}
      />
    </>

  )
}

export default HomePageImageMapper;