import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SendNotificationModal from './SendNotificationModal';

const Sidebar = ({show,handleClose}) =>  {
  const [isSendNotificationModalOpen, setIsSendNotificationModalOpen] = useState(false);

  const handleCloseSendNotificationModal = () => setIsSendNotificationModalOpen(false);
  const handleOpenSendNotificationModal = () => setIsSendNotificationModalOpen(true);

  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Admin Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         <ul style={{listStyle:"none"}}>
            <li onClick={handleOpenSendNotificationModal}>
                Send Notification
            </li>
         </ul>
        </Offcanvas.Body>
      </Offcanvas>

      {isSendNotificationModalOpen && <SendNotificationModal
      show={isSendNotificationModalOpen}
      handleCloseSendNotificationModal={handleCloseSendNotificationModal}
      />}
    </>
  );
}

export default Sidebar;



