// import "../../css/ChannelPartner/NewCSS/responsive.css";
import React from "react";
import _ from "lodash";
import "../css/ChannelPartner/NewCSS/headsearch.css";
import hpeLogo from "../img/ChannelPartner/hpe_logo.png";
import DeltaHomeContext from "../Context/DeltaHomeContext";
import Multimedia from "../img/ChannelPartner/HeadSearch/Multimedia.jpg";
import Guides from "../img/ChannelPartner/HeadSearch/Guides.jpg";
import CustReference from "../img/ChannelPartner/HeadSearch//Customer-Reference-of-Case-Study.jpg";
import Announcemnet from "../img/ChannelPartner/HeadSearch/Announcement-and-Communication.jpg";
import BestPractices from "../img/ChannelPartner/HeadSearch/Best-Practices.jpg";
import Presentation from "../img/ChannelPartner/HeadSearch/Presentation.jpg";
import SuccessStory from "../img/ChannelPartner/HeadSearch/Success-Story.jpg";
import RefrenceArchitecture from "../img/ChannelPartner/HeadSearch/ReferenceArchitecture.jpg";
import CompetetiveInsights from "../img/ChannelPartner/HeadSearch/Competetive-Insight.jpg";
import InternalTrainings from "../img/ChannelPartner/HeadSearch/Internal-Training.jpg";
import AceeleratorsBook from "../img/ChannelPartner/HeadSearch/Accelerators.jpg";
import MarketInsights from "../img/ChannelPartner/HeadSearch/Market-Insight.jpg";
import Greenlake from "../img/ChannelPartner/HeadSearch/Greenlake.jpg";
import Github from "../img/ChannelPartner/HeadSearch/Github.jpg";
import ProcessDocuments from "../img/ChannelPartner/HeadSearch/Process-Documents.jpg";
import Demo from "../img/ChannelPartner/NewImg/Demo.png";
import ChannelPartnerHeadSearchModal from "./ChannelPartnerHeadSearchModal";
import logo from "../img/loading-icon-animated.gif";
import TrackingService from "../TrackingService";
import Cookies from "js-cookie";
import profile from "../img/ChannelPartner/HeadSearch/NewImg/profile.png";
import Avatar from "react-avatar";
import ChannelPartnerHeader from "./ChannelPartnerHeader";
import ChannelHeadImg from "../img/ChannelPartner/HeadSearch/NewImg/head-main.webp"

import { OverlayTrigger, Tooltip, Navbar, Modal } from "react-bootstrap";
import axios from "axios";
import URLConfig from "../URLConfig";
import ReactOwlCarousel from "react-owl-carousel";
import Sidebar from "./Admin/SideBar";
const adminUsers = ["nitin-kaveriappa.udiyanda-muthanna@hpe.com","arpan.biswas@hpe.com","pratiksha.shashikant-pawar@hpe.com"]

class ChannelPartnerHeadSearchResults extends React.Component {
  static contextType = DeltaHomeContext;
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.startPosition = React.createRef(0);
    this.state = {
      bookShelf: [
        { name: "multimedia", book: Multimedia },
        { name: "guides", book: Guides },
        { name: "customerreference", book: CustReference },
        { name: "announcements", book: Announcemnet },
        { name: "bestpratices", book: BestPractices },
        { name: "presentations", book: Presentation },
        { name: "successstory", book: SuccessStory },
        { name: "referencearchitecture", book: RefrenceArchitecture },
        { name: "competetiveinsights", book: CompetetiveInsights },
        { name: "internaltrainings", book: InternalTrainings },
        { name: "accelerators", book: AceeleratorsBook },
        { name: "marketinsights", book: MarketInsights },
        { name: "greenlake", book: Greenlake },
        { name: "github", book: Github },
        { name: "processdocument", book: ProcessDocuments },
        { name: "demo", book: Demo },
      ],

      availableShelfCategories: [],
      showModal: false,
      selectedDocumentData: {},
      isSidebarOpen:false
      // rack1Categories: [],
      // rack2Categories: [],
    };
  }
 
  componentDidMount = () => {
    console.log(" componentDidMount channelPartnerSearch", this.props);
    // this.updateCategories();
    // this.forceUpdate()
  };

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(prevProps.relevancyScore, this.props.relevancyScore)) {
      console.log(" componentDidUpdate channelPartnerSearch", this.props);
      // this.updateCategories();
    }
  };

  componentWillUnmount = () => {
    this.setState({ rack1Categories: [], rack2Categories: [] });
    this.componentRef = React.createRef();
    this.startPosition = React.createRef(0);
  };

  updateCarouselPosition = (object) => {
    if (object.item.index != this.startPosition.current)
      this.startPosition.current = object.item.index;
  };

  updateCategories = () => {
    console.log("updateCategories");
    const documentsList = this.props.docs;
    let searchResults = [];
    const GuidesData =
      this.props.GuidesData && this.props.GuidesData.groups?.length
        ? this.props.GuidesData
        : null;
    console.log("GuidesData", GuidesData);
    GuidesData &&
      searchResults.push({
        ...this.state.bookShelf[1],
        data: GuidesData,
        documentType: "Guides",
      });

    const PresentationsData =
      this.props.Presentations && this.props.Presentations.groups?.length
        ? this.props.Presentations
        : null;
    console.log("PresentationsData", PresentationsData);
    PresentationsData &&
      searchResults.push({
        ...this.state.bookShelf[5],
        data: PresentationsData,
        documentType: "Presentations",
      });

    const ServicesData =
      this.props.ServicesData && this.props.ServicesData.groups?.length
        ? this.props.ServicesData
        : null;
    console.log("ServicesData", ServicesData);
    ServicesData &&
      searchResults.push({
        ...this.state.bookShelf[5],
        data: ServicesData,
        documentType: "Services",
      });

    const MultimediaData =
      this.props.MultimediaData && this.props.MultimediaData.groups?.length
        ? this.props.MultimediaData
        : null;

    console.log("MultimediaData", MultimediaData);
    MultimediaData &&
      searchResults.push({
        ...this.state.bookShelf[0],
        data: MultimediaData,
        documentType: "Multimedia",
      });

    const POCList =
      this.props.POCList && this.props.POCList?.length
        ? this.props.POCList
        : null;
    console.log("POCList", POCList);
    POCList &&
      searchResults.push({
        ...this.state.bookShelf[0],
        data: POCList,
        documentType: "POC Central",
      });

    const TrainingsData =
      this.props.TrainingsData && this.props.TrainingsData.groups?.length
        ? this.props.TrainingsData
        : null;
    console.log("TrainingsData", TrainingsData);
    TrainingsData &&
      searchResults.push({
        ...this.state.bookShelf[9],
        data: TrainingsData,
        documentType: "Internal Trainings",
      });

    const AcceleratorData = this.props.acceleratorData
      ? this.props.acceleratorData
      : null;
    console.log("AcceleratorData", AcceleratorData);
    AcceleratorData &&
      searchResults.push({
        ...this.state.bookShelf[11],
        data: AcceleratorData,
        documentType: "Aceelerators",
      });

    const greenLakeObject = documentsList?.find((list) => {
      return list.groupValue === "Greenlake";
    });
    console.log("greenLakeObject", greenLakeObject);
    greenLakeObject &&
      searchResults.push({
        ...this.state.bookShelf[12],
        data: greenLakeObject,
        documentType: "Greenlake",
      });

    const acceleratorsObject = documentsList?.find((list) => {
      return list.groupValue === "Accelerators";
    });
    console.log("acceleratorsObject", acceleratorsObject);
    acceleratorsObject &&
      searchResults.push({
        ...this.state.bookShelf[10],
        data: acceleratorsObject,
        documentType: "Accelerators",
      });
    const githubObject = documentsList?.find((list) => {
      return list.groupValue === "Github";
    });
    console.log("githubObject", githubObject);
    githubObject &&
      searchResults.push({
        ...this.state.bookShelf[13],
        data: githubObject,
        documentType: "Github",
      });

    const referenceArchitecturesObject = documentsList?.find((list) => {
      return list.groupValue === "Reference Architectures";
    });
    console.log("referenceArchitecturesObject", referenceArchitecturesObject);
    referenceArchitecturesObject &&
      searchResults.push({
        ...this.state.bookShelf[7],
        data: referenceArchitecturesObject,
        documentType: "Reference Architectures",
      });

    const marketInsightsObject = documentsList?.find((list) => {
      return list.groupValue === "Market Insights";
    });
    console.log("marketInsightsObject", marketInsightsObject);
    marketInsightsObject &&
      searchResults.push({
        ...this.state.bookShelf[11],
        data: marketInsightsObject,
        documentType: "Market Insights",
      });

    const successStoryObject = documentsList?.find((list) => {
      return list.groupValue === "Success Story";
    });
    console.log("successStoryObject", successStoryObject);
    successStoryObject &&
      searchResults.push({
        ...this.state.bookShelf[6],
        data: successStoryObject,
        documentType: "Success Story",
      });

    const announcementsAndCommunicationsObject = documentsList?.find((list) => {
      return list.groupValue === "Announcements and Communications";
    });
    console.log(
      "announcementsAndCommunicationsObject",
      announcementsAndCommunicationsObject
    );
    announcementsAndCommunicationsObject &&
      searchResults.push({
        ...this.state.bookShelf[3],
        data: announcementsAndCommunicationsObject,
        documentType: "Announcements and Communications",
      });

    const customerReferencesOrCaseStudyObject = documentsList?.find((list) => {
      return list.groupValue === "Customer References or Case Study";
    });
    console.log(
      "customerReferencesOrCaseStudyObject",
      customerReferencesOrCaseStudyObject
    );
    customerReferencesOrCaseStudyObject &&
      searchResults.push({
        ...this.state.bookShelf[2],
        data: customerReferencesOrCaseStudyObject,
        documentType: "Customer References or Case Study",
      });

    const competitiveInsightsObject = documentsList?.find((list) => {
      return list.groupValue === "Competitive Insights";
    });
    console.log("competitiveInsightsObject", competitiveInsightsObject);
    competitiveInsightsObject &&
      searchResults.push({
        ...this.state.bookShelf[8],
        data: competitiveInsightsObject,
        documentType: "Competitive Insights",
      });

    const bestPracticesObject = documentsList?.find((list) => {
      return list.groupValue === "Best Practices";
    });
    console.log("bestPracticesObject", bestPracticesObject);
    bestPracticesObject &&
      searchResults.push({
        ...this.state.bookShelf[4],
        data: bestPracticesObject,
        documentType: "Best Practices",
      });

    const processDocumentsObject = documentsList?.find((list) => {
      return list.groupValue === "Process Documents";
    });
    console.log("processDocumentsObject", processDocumentsObject);
    processDocumentsObject &&
      searchResults.push({
        ...this.state.bookShelf[14],
        data: processDocumentsObject,
        documentType: "Process Documents",
      });

    const demoObject = documentsList?.find((list) => {
      return list.groupValue === "Demo";
    });
    console.log("processDocumentsObject", demoObject);
    demoObject &&
      searchResults.push({
        ...this.state.bookShelf[15],
        data: demoObject,
        documentType: "Demo",
      });

    console.log("searchResultssearchResults", searchResults);
    let rackFirstIndex = 0;
    let rackSecondIndex = 0;
    // let updatedSearchResults = searchResults.map((item, index) => {
    //         rackFirstIndex = rackFirstIndex >= 6 ? 1 : rackFirstIndex + 1
    //         rackSecondIndex = rackFirstIndex === 1 ? rackSecondIndex + 1 : rackSecondIndex;
    //         return {
    //             ...item,
    //             rackIndex : rackFirstIndex.toString() + rackSecondIndex.toString()
    //     }
    // })
    let updatedSearchResults = searchResults.map((item, index) => {
      rackFirstIndex = rackFirstIndex >= 2 ? 1 : rackFirstIndex + 1;
      //   if (rackFirstIndex === 1) {
      //     this.setState((currenState) => {
      //       return {
      //         rack1Categories: [
      //           ...currenState.rack1Categories,
      //           {
      //             ...item,
      //             rackIndex: rackFirstIndex,
      //           },
      //         ],
      //       };
      //     });
      //   } else if (rackFirstIndex === 2) {
      //     this.setState((currenState) => {
      //       return {
      //         rack2Categories: [
      //           ...currenState.rack2Categories,
      //           {
      //             ...item,
      //             rackIndex: rackFirstIndex,
      //           },
      //         ],
      //       };
      //     });
      //   }
      // rackSecondIndex = rackFirstIndex === 1 ? rackSecondIndex + 1 : rackSecondIndex;

      return {
        ...item,
        rackIndex: rackFirstIndex,
      };
    });
    let updatedRack1Categories = updatedSearchResults.filter((data) => {
      return data.rackIndex === 1;
    });
    let updatedRack2Categories = updatedSearchResults.filter((data) => {
      return data.rackIndex === 2;
    });
    console.log("updatedSearchResults", updatedSearchResults);
    // this.setState({ availableShelfCategories: updatedSearchResults });
    // this.setState({
    //   rack1Categories: updatedRack1Categories,
    //   rack2Categories: updatedRack2Categories,
    // });
    return {
      rack1Categories: updatedRack1Categories,
      rack2Categories: updatedRack2Categories,
    };
  };

  handleBookClick = async (bookItem, isShowModal = true) => {
    console.log("bookItembookItem", bookItem);

    this.setState({ showModal: true, selectedDocumentData: bookItem });
  };

  showHideModal = () => {
    console.log("showHideModal");
    this.setState({ showModal: !this.state.showModal });
  };

  logDocClick = (documentDetails) => {
    console.log("logDocClick");
    // this.setState({ documentDetailsAll: documentDetails });
    if (!this.TrackingService) {
      this.TrackingService = new TrackingService();
    }
    this.TrackingService.OpenLink(Cookies.get("empnumber"), documentDetails);
  };

  handleOpenSideBar =()=>{
    this.setState({
      isSidebarOpen:true
    })

  }

  handleCloseSideBar =()=>{
    this.setState({
      isSidebarOpen:false
    })

  }

  render() {
    // this.updateCategories();
    const { rack1Categories, rack2Categories } = this.updateCategories();
    return (
      <>
        <div id="content" className="cp_header-main">
          <header className="cp_header">
            <div className="col-12 row px-3 d-flex align-items-center justify-content-center cp_header_col">
              <div className="col-1">
                {/* <img src="images/hpe_logo.png" width="120" alt=""/> */}
                <Navbar.Brand href="/">
                  <img
                    style={{ width: "8vw" }}
                    src={hpeLogo}
                    width="120"
                    height="50"
                    alt=""
                  />{" "}
                </Navbar.Brand>
              </div>
              <div className="col-10 cp_header-text">
                <p className="cp_header-text_1">
                  Hello {Cookies.get("name")?.split(" ")[0]}! Welcome to the
                  Digital Library
                </p>
              </div>
              
              <div className="col-1 cp_header-profile">
              {adminUsers.includes(localStorage.getItem("mail")) &&
              <i class="fa-xs fa fa-cog" style={{fontSize: "6vh",paddingRight: "1vw"}} onClick={this.handleOpenSideBar}></i>
  }
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  overlay={
                    <Tooltip className=" homepage-profile-tooltip">
                      <>
                        <div>
                          {Cookies.get("empnumber") !== undefined
                            ? Cookies.get("name")
                            : "User"}
                        </div>
                        {/* <div style={{ fontSize: "10px" }}>
                  {Cookies.get("roles")
                    ? Cookies.get("roles").split(",").join(", ")
                    : "Guest"}
                </div> */}
                      </>
                    </Tooltip>
                  }
                >
                  <div>
                    <Avatar
                      // onClick={() => handleClickUserProfile()}
                      className="homepage-profile-avatar"
                      color={Avatar.getRandomColor("sitebase", [
                        "red",
                        "green",
                        "blue",
                      ])}
                      name={
                        Cookies.get("empnumber") !== undefined
                          ? Cookies.get("name")
                          : "User"
                      }
                      title=""
                      size="35"
                      round
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </header>

{this.context.onInitiateSearchCompleted &&rack1Categories.length ===0 &&rack1Categories.length ===0?(
  <>

<div className="pt-3 mx-auto">
<button className="ChannelPartner-centerDiv shadow">
  <div className="titlealign">No Results Found</div> 
</button>

</div>


</>
):(<>
          <div align="center" className="cp_searchhead-main">
            <div
              id="head"
              // className="cp_searchhead-head"
            >
                  <img src={ChannelHeadImg} style={{position:"absolute",objectFit:"contain",width:"100%",bottom:"0vh"}}/>
                  <div style={{aspectRatio:"698/767",position:"absolute",width:"100%",bottom:"0vh"}}>                
              {this.context.showLoader ? (
                <div
                  className="text-center"
                  style={{
                    position: "absolute",
                    left: "18vw",
                    bottom: "-60vh",
                  }}
                >
                  <img className="loading-img" src={logo} alt="loading"></img>
                </div>
              ) : (
                <>
                  <div
                    // key={index}
                    className={
                      "cp_searchhead-rack_1 col-12 row CP-head-books-gap "
                    }
                    // id="rack1"
                    // className={"cp_searchhead-rack_"+ list.rackIndex + " cp_searchhead_img-"+list.name} id="rack1"
                  >
                    {rack1Categories.length > 0 ? (
                      <ReactOwlCarousel
                        items={4}
                        className="owl-theme"
                        nav
                        dotClass="custom-owl-dots"
                        navContainerClass={
                          rack1Categories.length <= 4
                            ? "owl-nav custom-nav-class"
                            : "owl-nav"
                        }
                        style={{
                          width: "20vw",
                          position: "relative",
                        }}
                        startPosition={this.startPosition.current}
                        onDragged={(object) => {
                          console.log("object", object);
                          this.updateCarouselPosition(object);
                        }}
                        onChanged={(object) => {
                          console.log("object onchange", object);
                          this.updateCarouselPosition(object);
                        }}
                      >
                        {rack1Categories.map((list, index) => {
                          return (
                            <div
                              className="CP-head-book1 cp_searchhead-rackbook col"
                              onClick={() => this.handleBookClick(list)}
                              id={index}
                            >
                              <div className="CP-head-book-wrap">
                                <div className="CP-head-book-items">
                                  <div className="CP-head-main-book-wrap">
                                    <div className="CP-head-book-cover">
                                      <div className="CP-head-book-inside"></div>
                                      <div className="CP-head-book-image">
                                        <img src={list.book} />
                                        <div className="CP-head-effect"></div>
                                        <div className="CP-head-light"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </ReactOwlCarousel>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    // key={index}
                    className={
                      "cp_searchhead-rack_2 col-12 row CP-head-books-gap "
                    }
                    // id="rack1"
                    // className={"cp_searchhead-rack_"+ list.rackIndex + " cp_searchhead_img-"+list.name} id="rack1"
                  >
                    {rack2Categories.length > 0 ? (
                      <ReactOwlCarousel
                        items={4}
                        className="owl-theme"
                        nav
                        dotClass="custom-owl-dots"
                        navContainerClass={
                          rack2Categories.length <= 4
                            ? "owl-nav custom-nav-class"
                            : "owl-nav"
                        }
                        style={{
                          width: "20vw",
                          position: "relative",
                        }}
                        startPosition={this.startPosition.current}
                        onDragged={(object) => {
                          console.log("object", object);
                          this.updateCarouselPosition(object);
                        }}
                        onChanged={(object) => {
                          console.log("object onchange", object);
                          this.updateCarouselPosition(object);
                        }}
                      >
                        {rack2Categories.map((list, index) => {
                          return (
                            <div
                              className="CP-head-book1 cp_searchhead-rackbook col"
                              onClick={() => this.handleBookClick(list)}
                              id={index}
                            >
                              <div className="CP-head-book-wrap">
                                <div className="CP-head-book-items">
                                  <div className="CP-head-main-book-wrap">
                                    <div className="CP-head-book-cover">
                                      <div className="CP-head-book-inside"></div>
                                      <div className="CP-head-book-image">
                                        <img src={list.book} />
                                        <div className="CP-head-effect"></div>
                                        <div className="CP-head-light"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </ReactOwlCarousel>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
</div>
              
            </div>
          </div>
          </>)}
          <div
            align="center"
            id="footer"
            style={{
              width: "100vw",
              position: "absolute",
              bottom: "0",
              paddingTop: "1vh",
              color: "#ffffff",
              fontSize: "1.5vw"
            }}
          >
            © Copyright 2024 Hewlett Packard Enterprise Development LP
          </div>
        </div>
        {this.state.showModal && (
          <ChannelPartnerHeadSearchModal
            show={this.state.showModal}
            documentDetails={this.state.selectedDocumentData}
            showHideModal={this.showHideModal}
            filters={this.props.filters}
            searchTerm={this.props.searchTerm}
            logDocClick={this.logDocClick}
          />
        )}
       <Sidebar
       show={this.state.isSidebarOpen}
       handleClose={this.handleCloseSideBar}
       />
      </>
    );
  }
}

export default ChannelPartnerHeadSearchResults;
