import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const CustomToast = ({title,body,url}) => {
  return (
    <Card onClick={()=>{
        window.open(url,"_blank")
    }}>
    <Card.Body>
      <Card.Title>
        <img src="https://delta.app.hpecorp.net/delta-favicon.PNG" style={{height:"30px",width:"30px"}} className='me-2 mb-1'/>
        {title}</Card.Title>
      <Card.Text>
       {body}
       </Card.Text>
    </Card.Body>
  </Card>
  )
}

export default CustomToast