import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import each from "lodash/each";
import map from "lodash/map";
import some from "lodash/some";
import findIndex from "lodash/findIndex";
import includes from "lodash/includes";
import CryptoJS from "crypto-js";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/"
import visio from "../img/visio-icon.jpg";
import useSWR from "swr";

import URLConfig from "../URLConfig";
import {
  identifyFileFormat,
  ISValidFileForPreview,
} from "../utils/FileType";
import { UserContext } from "../Context/UserContext";
import {
  DOC_SHARE,
  DOC_PREVIEW,
  DOC_COMMENT,
  DOC_META,
  DOC_UPDATE,
  DOC_LIKE,
} from "../Common/Constants";
import {
  fetchUserBookmarkDetails,
  fetchUserFeedbackDetails,
} from "../Common/GeneralFunctions";
import FileDownload from "./FileDownload";
import deltaCover  from "../img/ChannelPartner/delta-cover.jpg"
import Img25 from "../img/ChannelPartner/NewImg/25.png"
import Img28 from "../img/ChannelPartner/NewImg/28.png"
import Img30 from "../img/ChannelPartner/NewImg/30.png"
import Img31 from "../img/ChannelPartner/NewImg/31.png"
import Img32 from "../img/ChannelPartner/NewImg/32.png"
import Img34 from "../img/ChannelPartner/NewImg/34.png"
import technologyAndProduct from "../img/ChannelPartner/NewImg/technology-and-product.png"
import customerPresent from "../img/ChannelPartner/NewImg/customer-present.png"
import partnerpresent from "../img/ChannelPartner/NewImg/partner-present.png"
import trophyOffwhite from "../img/ChannelPartner/NewImg/trophy-offwhite.png"
import others from "../img/ChannelPartner/NewImg/Others.jpg"
import ReactOwlCarousel from "react-owl-carousel";

const fetcher = (url) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        host: window.location.href,
        type: "Open_Document_Link",
      },
    };

    axios(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const Documents = ({
  docList,
  size = 0,
  logDocClick,
  pursuitView,
  showPreview = true,
  viewDocument,
  groupValue,
  showCarousal,
  noOfBooks,
  getImage,
  setCurrentDocURL
}) => {
  //const { data: allUserRatingData_V2, error } = useSWR(URLConfig.GetAllDocRatings(), fetcher);

  const signal = axios.CancelToken.source();
  const [userDetails, dispatch] = useContext(UserContext);
  const [bookmarks, setBookmarks] = useState([]);
  const [previewURL, setPreviewURL] = useState([{ url: "", doc_Link: "" }]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [loader, setLoader] = useState(false);
  const [errData, setErrData] = useState({});
  const [docShare, setDocShare] = useState({ via: "true", email: "" });
  const [docMeta, setDocMeta] = useState({ fileName: "", data: {} });
  const [docLike, setDocLike] = useState({ comment: "" });
  const [docUpdateOptions, setDocUpdateOptions] = useState([]);
  const [userRatingData, setUserRatingData] = useState([]);
  //const [allUserRatingData, setAllUserRatingData] = useState(allUserRatingData_V2);
  
  const [docUpdate, setDocUpdate] = useState({
    updateRequest: "",
    addComments: "",
  });
  const [modal, setModal] = useState({
    [DOC_PREVIEW]: false,
    [DOC_SHARE]: false,
    [DOC_UPDATE]: false,
    [DOC_META]: false,
    [DOC_COMMENT]: false,
  });
  const [button, setButton] = useState({
    [DOC_PREVIEW]: false,
    [DOC_SHARE]: true,
    [DOC_UPDATE]: false,
    [DOC_META]: false,
    [DOC_COMMENT]: false,
  });
  const [rating, setRating] = useState(0);
  const [checkEmail, setCheckEmail] = useState(false);
  const [accelerator, setAccelerator] = useState(false);
  const [Results, setResults] = useState([]);
  useEffect(() => {
    return () => {
      signal.cancel("Request Cancelled");
    };
  }, []);
  // useEffect(() => {
  //   if (allUserRatingData_V2) {
  //     setAllUserRatingData(allUserRatingData_V2);
  //     // console.log(allUserRatingData)
  //   }
  // }, [allUserRatingData_V2]);

  useEffect(() => {
    if (userDetails && userDetails.userBookmarks)
      setBookmarks([...userDetails.userBookmarks]);
    if (userDetails && userDetails.userFeedbacks)
      setFeedbacks([...userDetails.userFeedbacks]);
  }, [userDetails]);
  // useEffect(() => {
  //   if (process.env.REACT_APP_ENV === "UAT") {
  //     console.log(Results);
  //     getAccelerator();
  //   }
  // }, []);

  const fetchUpdateRequestStatus = async (cancelToken) => {
    setLoader(true);
    const URL =
      URLConfig.getURL_UserDocument() + "/DocumentRequestUpdateStatus";
    try {
      const res = await axios.get(URL, { cancelToken });
      if (res?.data?.length) {
        setDocUpdateOptions([...res.data]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  // const getAccelerator = () => {
  //   const URL =
  //     URLConfig.getURLDeltaAPI() +
  //     "GreenlakeDocument/RPS_05_Accelerators_DBPA_Squest_16102023_v1.1.html";
  //   axios.get(URL).then((res) => {
  //     if (res.data) {
  //       setResults(res.data);
  //     }
  //   });
  // }

  // useEffect(() => {
  //   processData(docList);
  // }, [docList, activePage]);

  // const processData = (data) => {
  //   if (data && data.length > 0) {
  //     console.log({ data });
  //     // Remove docs that do not have Account_ST_Name
  //     const processedData = filter(
  //       data,
  //       (item) =>
  //         includes(trim(lowerCase(item.file)), trim(lowerCase(searchKey))) ||
  //         includes(trim(lowerCase(item.doc_source)), trim(lowerCase(searchKey)))
  //     );

  //     //   const processedData = [
  //     //     ..._.filter(data, (obj) => {
  //     //       return _.some(_.keys(obj), (key) => {
  //     //         return _.includes(obj[key], searchKey);
  //     //       });
  //     //     }),
  //     //   ];

  //     console.log({ processedData });
  //     setModalDocList(processedData);
  //   } else {
  //     setModalDocList([]);
  //   }
  // };
  const findInitialRatingbyUser = () => {
    const url = URLConfig.GetDocRatingByUser();
    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        UserId: Cookies.get("empnumber"),
      },
    };
    try {
      axios(config).then(function (response) {
        // console.log(response.data);
        // setUserRatingData(
        //   response.errData
        // );
        setUserRatingData(
          response.data
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDocRatingData = () => {
    let url = URLConfig.GetAllDocRatings();
    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        host: window.location.href,
        type: "Open_Document_Link",
      },
    };
    try {
      axios(config).then(function (response) {
        //setAllUserRatingData(response?.data)
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleLikeDislike = () => {
    if (docLike.isLiked || (!docLike.isLiked && docLike.comment)) {
      const feedback = {
        userId: Cookies.get("empnumber"),
        documentID: docLike.docID,
        isLiked: docLike.isLiked,
        Comments: docLike.comment,
      };
      axios
        .put(URLConfig.getURL_UserFeedBack(), feedback)
        .then(() => {
          setFeedbacks((prevState) => {
            const index = findIndex(prevState, {
              documentID: feedback.documentID,
              userId: feedback.userId,
            });
            return index === -1
              ? [...prevState, feedback]
              : [
                  ...prevState.slice(0, index),
                  feedback,
                  ...prevState.slice(index + 1),
                ];
          });
          fetchUserFeedbackDetails(dispatch);
          handleModalClose(DOC_LIKE);
          toast.info("Thanks for your feedback..", {
            position: "bottom-left",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleModalOpen = (item) => {
    setModal((prevState) => ({ ...prevState, [item]: true }));
  };
  const handlePreview = (doc) => {
    logDocClick(doc);
    let url = doc.url.substring(0, 26)
    var filepath =
      "F:\\AnPS\\Sharepointfiles\\" +
      doc.url
        .replace(url, "")
        .replace(/ /g, "%20")
        .split("/")
        .join("\\");
    filepath = CryptoJS.enc.Utf8.parse(filepath);
    filepath = CryptoJS.enc.Base64.stringify(filepath);
    const previewURL = "https://hpedelta.com/Viewer.aspx?file=" + filepath;
    // setCurrentDocURL(previewURL)
    // console.log(previewURL);
    setPreviewURL((prevState) => ({
      ...prevState,
      url: previewURL,
      doc_Link: doc.url,
    }));
    handleModalOpen(DOC_PREVIEW);
  };

  const handleModalClose = (item) => {
    setModal((prevState) => ({ ...prevState, [item]: false }));
  };

  const handleCheckChange = (event) => {
    const value = event.target.value === "true" ? true : false;
    setDocShare((prevState) => ({ ...prevState, via: value }));
  };

  const validateEmail = (email) => {
    // const regEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    const regExHPE =
      /[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@hpe\.com$/;
    return email.match(regExHPE);
  };

  // const handleEmailChange = (event) => {
  //   const value = trim(event.target.value);
  //   if (validateEmail(value)) {
  //     setErrData((prevState) => ({ ...prevState, email: "" }));
  //   } else {
  //     setErrData((prevState) => ({
  //       ...prevState,
  //       email: "Please enter one valid HPE Email Id",
  //     }));
  //   }
  //   setDocShare((prevState) => ({ ...prevState, email: value }));
  // };

  const checkEmailValidity = (email) => {
    if (isEmail(email) && validateEmail(email)) {
      setErrData((prevState) => ({ ...prevState, email: "" }));
    } else {
      setErrData((prevState) => ({
        ...prevState,
        email: "Please enter only valid HPE Email Ids",
      }));
    }
    return isEmail(email) && validateEmail(email);
  };

  const handleMultiEmailChange = (emails) => {
    checkEmailsValid(emails);
    setDocShare((prevState) => ({ ...prevState, emails: [...emails] }));
  };

  const checkEmailsValid = (emails) => {
    setErrData((prevState) => ({
      ...prevState,
      emails: [],
      guest: [],
      email: "",
    }));
    setButton((prevState) => ({ ...prevState, [DOC_SHARE]: true }));
    if (emails.length > 0) {
      each(emails, async (email) => {
        try {
          let URL =
            URLConfig.getURL_UserBookMark() + "/CheckEmailValid/" + email;
            setCheckEmail(true)
          const resCheckEmailValid = await axios.get(URL);
          if (!resCheckEmailValid.data) {
            setCheckEmail(false)
            setErrData((prevState) => {
              const errorEmails = [...prevState.emails, email];
              return { ...prevState, emails: errorEmails };
            });
          } else {
            setCheckEmail(false)
            URL = URLConfig.getURL_UserBookMark() + "/CheckUserValid/" + email;
            setCheckEmail(true)
            const resCheckUserValid = await axios.get(URL);
            if (!resCheckUserValid.data) {
              setCheckEmail(false)
              setErrData((prevState) => {
                const guestEmails = [...prevState.guest, email];
                return { ...prevState, guest: guestEmails };
              });
            }
            else setCheckEmail(false)
          }
          setButton((prevState) => ({ ...prevState, [DOC_SHARE]: false }));

        } catch (error) {
          console.log(error);
          setButton((prevState) => ({ ...prevState, [DOC_SHARE]: false }));
        }
      });
    } else {
      setButton((prevState) => ({ ...prevState, [DOC_SHARE]: false }));
    }
  };

  const handleCommentChange = (event) => {
    const value = event.target.value;
    setDocLike((prevState) => ({ ...prevState, comment: value }));
  };

  const handleShare = async (item) => {
    setButton((prevState) => ({ ...prevState, [item]: true }));
    if (docShare.emails && docShare.emails.length > 0) {
      each(docShare.emails, async (email) => {
        setButton((prevState) => ({ ...prevState, [item]: true }));
        let shareDetails = {};
        let URL = "";
        if (docShare.via) {
          URL = URLConfig.getURL_UserBookMark() + "/ShareViaEmail";
          shareDetails = {
            documentName: docShare.doc.file,
            URL: encodeURI(docShare.doc.url),
            toEmailAddress: email,
            sharedByEmail: Cookies.get("mail"),
          };
        } else {
          URL = URLConfig.getURL_UserBookMark() + "/ShareViaDelta";
          shareDetails = {
            documentName: docShare.doc.file,
            documentURL: docShare.doc.url,
            emailID: email,
            userId: Cookies.get("empnumber"),
            sharedByName: Cookies.get("name"),
            isNew: true,
            isArchived: docShare.doc.isarchived || false,
          };
        }
        try {
          const res = await axios.post(URL, shareDetails);
          handleModalClose(DOC_SHARE);
          toast.info("Shared Document Successfully", {
            position: "bottom-left",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } catch (error) {
          console.log(error);
          toast.error("Share Document Failed - Check if Email Id is Valid", {
            position: "bottom-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        setButton((prevState) => ({ ...prevState, [DOC_SHARE]: false }));
      });
    } else {
      setErrData((prevState) => ({
        ...prevState,
        email: "Please enter one valid HPE Email Id",
      }));
      setButton((prevState) => ({ ...prevState, [DOC_SHARE]: false }));
    }
  };

  const handleAddBookmark = (doc) => {
    const bookmark = {
      isBookmarked: true,
      documentID: doc.id,
      userId: Cookies.get("empnumber"),
      bookmarkURL: doc.url,
      documentName: doc.file,
      isArchived: doc.isarchived || false,
    };
    setBookmarks((prevState) => [...prevState, bookmark]);
    axios
      .post(URLConfig.getURL_UserBookMark() + "/userbookmark", bookmark)
      .then(() => {
        // setBookmarks((prevState) => [...prevState, bookmark]);
        fetchUserBookmarkDetails(dispatch);
        toast.info("Added to Bookmarks", {
          position: "bottom-left",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const fetchMetadata = (fileName) => {
  //   setLoader(true);
  //   const URL = `${URLConfig.get_APIHost()}solr/sharepoint_index/select?indent=on&q=file:%22${fileName}%22&wt=json`;
  //   axios.get(URL).then((res) => {
  //     if (res.data) {
  //       if (
  //         res.data.response &&
  //         res.data.response.numFound === 1 &&
  //         res.data.response.docs &&
  //         res.data.response.docs.length > 0
  //       ) {
  //         setDocMeta({ fileName, data: { ...res.data.response.docs[0] } });
  //       }
  //       setLoader(false);
  //     } else {
  //       console.log("No Metadata Found!!");
  //       setLoader(false);
  //     }
  //   });
  // };

  const handleDocUpdate = (event) => {
    const { name, value } = event.target;
    setDocUpdate((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDocUpdateSave = async (item) => {
    setButton((prevState) => ({ ...prevState, [item]: true }));
    if (docUpdate.updateRequest) {
      URL = URLConfig.getURL_UserDocument() + "/DocumentRequestUpdate";
      const updateRequestDetails = {
        documentID: docUpdate.docID,
        documentName: docUpdate.docName,
        documentURL: encodeURI(docUpdate.docURL),
        updateRequest: docUpdate.updateRequest,
        additionalComments: docUpdate.addComments,
        requestedBy: Cookies.get("empnumber"),
        requestedByEmail: Cookies.get("mail"),
      };
      try {
        await axios.post(URL, updateRequestDetails);

        handleModalClose(DOC_UPDATE);
        toast.info("Request Saved Successfully", {
          position: "bottom-left",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } catch (error) {
        console.log(error);
      }
    }
    setButton((prevState) => ({ ...prevState, [item]: false }));
  };

  const isSize = (index, size) => {
    return size ? index < size : true;
  };

  const isBookmarked = (bookmarks, url) => {
    return some(bookmarks, {
      bookmarkURL: url,
      isBookmarked: true,
    });
  };

  const isLikeDislike = (feedbacks, docID, bool) => {
    return some(feedbacks, {
      documentID: docID,
      isLiked: bool,
    });
  };

  const ISEditable = (FileName) => {
    return (
      FileName.indexOf("doc") !== -1 ||
      FileName.indexOf("xl") !== -1 ||
      FileName.indexOf("ppt") !== -1
    );
  };

  const GetPursuitUrl = (doc_Url) => {
    var DocumentUrl = CryptoJS.enc.Utf8.parse(
      doc_Url + "$$$$" + Cookies.get("empnumber") + "$$$$" + Cookies.get("name")
    );
    DocumentUrl =
      "https://delta.app.hpecorp.net:8443/Aceoffix/editWord?q=" +
      CryptoJS.enc.Base64.stringify(DocumentUrl);
    return DocumentUrl;
  };

  const isDirectDownloadDisabled = (url) => {
    if (url.includes("https://hpedelta.com:8083/") || url.includes("https://hpedelta.com:8082/"))
      return false;
    else return true;
  };

  const isChannelPartner = localStorage.getItem("isChannelPartner")
  const startPosition = useRef(0);
  const updateCarouselPosition =(object) =>
  { 
    if(object.item.index != startPosition.current)
     startPosition.current = object.item.index;
   } 

   const showImg = ()=>{
    if(groupValue ==="Greenlake"){
      return others
   }
   else if(groupValue ==="Accelerators"){
return others
   }
   else if(groupValue ==="Reference Architectures"){
return others
   }
   else if(groupValue ==="Market Insights"){
return others
   }
   else if(groupValue ==="Success Story"){
    return others
   }
   else if(groupValue ==="Announcements and Communications"){
    return others
   }
   else if(groupValue ==="Customer References or Case Study"){
    return others
   }
   else if(groupValue ==="Competitive Insights"){
    return others
   }
   else if(groupValue ==="Best Practices"){
    return others
   }
   else if(groupValue ==="Process Documents"){
    return others
   }
   
  }
  return (
    <div>
     {isChannelPartner === "1" && showCarousal ?
        (
          <div>
            <ReactOwlCarousel items={noOfBooks}
              className='owl-theme'
              nav
              dotClass='custom-owl-dots'
              style={{ width: "8.8vw", position: "relative" }}
              startPosition={startPosition.current}
              onDragged={(object) => {
                console.log("object", object)
                updateCarouselPosition(object)
              }}
              onChanged={(object) => {
                console.log("object onchange", object)
                updateCarouselPosition(object)
              }}
            >
              {docList?.map((doc, index) => (

                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={<Tooltip style={{ border: "0px" }}>{doc.file}</Tooltip>}
                >

                  <div className="item" key={groupValue + "_" + index} onClick={() => {
                    if (ISValidFileForPreview(doc.file_type) && doc && doc.url &&
                      doc.url.indexOf("https://hpedelta.com:") !== -1) {
                      console.log("doc", doc)
                      if (groupValue === "Accelerators") {
                        viewDocument(doc.file)

                      }
                      else {
                        handlePreview(doc)
                      }
                    }
                    else {
                      window.open(doc.url, "_blank")

                    }
                  }}>
                    <div class="book-items">
                      <div class="main-book-wrap">
                        <div class="book-cover">
                          <div class="book-inside"></div>
                          <div class="book-image">

                            <img src={showImg()} />
                            <div class="effect"></div>
                            <div class="light"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </OverlayTrigger>
              ))}
            </ReactOwlCarousel>
            {modal[DOC_PREVIEW] && (
              <Modal
                show={modal[DOC_PREVIEW]}
                onHide={() => handleModalClose(DOC_PREVIEW)}
                dialogClassName="preview-modal"
                animation={false}
              >
                <Modal.Header>
                  <Modal.Title>Document Preview</Modal.Title>
                  <button
                    type="button"
                    translate="no"
                    onClick={() => handleModalClose(DOC_PREVIEW)}
                    class="btn-close"
                    data-bs-dismiss="modal"
                  >
                    {/* <i className="fa fa-times" style={{ fontSize: "15px" }}></i> */}
                  </button>
                </Modal.Header>
                <Modal.Body dialogClassName="preview-body">
                  <div className="preview_iframe">
                    <iframe frameborder="0" allowfullscreen src={previewURL.url} />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => handleModalClose(DOC_PREVIEW)}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            )}
          </div>

        ) : (
          <>
            { (docList.length > 0 &&
                docList.map(
                  (doc, index) =>
                    isSize(index, size) && (
                      <div className="border-bottom" key={doc.id}>
                        <div
                          className="col-12 ps-0 mb-1 ms-1 row pb-10"
                        // onClick={() => {
                        //   console.log(doc);
                        // }}
                        >
                          <div className="col-1 row pe-0 pt-1 me-3 ps-0">
                            {doc.file_type && doc.file_type.indexOf("vsd") !== -1 ? (
                              <img
                                src={visio}
                                //src="https://delta.app.hpecorp.net/static/media/visio-icon.jpg"
                                className="visio-icon"
                              />
                            ) : (
                              <a className={identifyFileFormat(doc.file_type)} />
                            )}
                          </div>
                          <div className={isChannelPartner === "1" ? "col-9 ps-0 row" : "col-6 ps-0 row"}>
                            <div className={isChannelPartner === "1" ?"col-12 row pe-0 ps-0" : "col-11 row pe-0 ps-0"}>
                              {isDirectDownloadDisabled(doc.url) ? (

                                <a
                                  href={doc.url}
                                  className={"breakall_word a-hover " + (isChannelPartner === "1" ? "channelPartner-modal-fs" : "")}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={() => {
                                    console.log("isDirectDownloadDisabled")
                                    logDocClick(doc)
                                  }}
                                >
                                  {doc.file}
                                  {doc.isarchived && (
                                    <span className="archivedDocs">A</span>
                                  )}
                                  {doc.isgoldcollateral && (
                                    <span className="goldCol">G</span>
                                  )}
                                </a>
                              ) : (
                                <>
                                  <FileDownload
                                    doc={doc}
                                    logDocClick={() => logDocClick(doc)}
                                  />
                                </>
                              )}
                            </div>
                           
                          </div>
                          <div className={isChannelPartner === "1" ? "col-1 ps-0 pe-0 mt-1" :  "col-4 ps-0 pe-0 mt-1"}>
                            <div className="col-12 row ps-0 ms-2">
                              {/* {showPreview && console.log(doc, groupValue)} */}
                              {/* doc.document_type condition is added to filter accelerator as category. date-21/12/23 */}
                              {showPreview &&
                                ISValidFileForPreview(doc.file_type) && doc && doc.url && (doc.document_type == "Accelerators" || doc.doc_source == "Accelerators") ?
                                (<div className="col-2 ps-0 mb-2" align="left">
                                  <i
                                    className="fas fa-eye pointer"
                                    title="Document Preview"
                                    onClick={() => { viewDocument(doc.file); logDocClick(doc) }}
                                  />
                                </div>) :
                                ISValidFileForPreview(doc.file_type) && doc && doc.url &&
                                  doc.url.indexOf("https://hpedelta.com:") !== -1 ? (
                                  <div className="col-2 ps-0 mb-2" align="left">
                                    <i
                                      className="fas fa-eye pointer"
                                      title="Document Preview"
                                      onClick={() => handlePreview(doc)}
                                    />
                                  </div>
                                ) : (
                                  <div className="col-2 ps-0 mb-2" align="left">
                                    <i
                                      className="fas fa-eye-slash"
                                      style={{ color: "grey" }}
                                      title="Document Preview Not Available"
                                    ></i>
                                  </div>
                                )}
                              {isChannelPartner === "1" ? null : (
                                <div className="col-2 ps-0">
                                  {isBookmarked(bookmarks, doc.url) ? (
                                    <i
                                      className="fas fa-heart disable"
                                      title="Added to Bookmark"
                                    />
                                  ) : (
                                    <i
                                      className="far fa-heart pointer"
                                      title="Add to Bookmark"
                                      onClick={() => handleAddBookmark(doc)}
                                    />
                                  )}
                                </div>
                              )}
                              {isChannelPartner === "1" ? null : (
                                <div className="col-2 ps-0">
                                  <i
                                    className="fas fa-share-alt pointer"
                                    title="Share"
                                    onClick={() => {
                                      setDocShare({
                                        via: "",
                                        email: "",
                                        doc: doc,
                                        url: doc.url,
                                        docID: doc.id,
                                        docName: doc.file,
                                      });
                                      setErrData({});
                                      handleModalOpen(DOC_SHARE);
                                      setButton((prevState) => ({
                                        ...prevState,
                                        [DOC_SHARE]: true,
                                      }));
                                    }}
                                  />
                                </div>
                              )}
                              {isChannelPartner === "1" ? null : (
                                <div className="col-2 ps-0">
                                  <i
                                    className="fas fa-crosshairs pointer"
                                    title="Request for Update"
                                    onClick={() => {
                                      setDocUpdate({
                                        updateRequest: "Version Update",
                                        addComments: "",
                                        docID: doc.id,
                                        docURL: doc.url,
                                        docName: doc.file,
                                      });
                                      fetchUpdateRequestStatus(signal.token);
                                      handleModalOpen(DOC_UPDATE);
                                    }}
                                  />
                                </div>
                              )}
                              {/* <div className="col-3 pl-0">
                        <i
                          className="far fa-file-alt pointer"
                          title="Metadata Details"
                          onClick={() => {
                            fetchMetadata(doc.file);
                            handleModalOpen(DOC_META);
                          }}
                        />
                      </div> */}
                              {/* <div className="col-3 pl-0" align="left">
                        <i
                          className="far fa-comment pointer"
                          title="Add Comment"
                          onClick={() => handleModalOpen(DOC_COMMENT)}
                        ></i>
                      </div> */}
                              {isChannelPartner === "1" ? null : (
                                <div className="col-2 ps-0" id={`tooltip-${doc.id}`}>
                                  {/* <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-${doc.id}`}>
                              {`Source: ${doc.doc_source}`}
                            </Tooltip>
                          }
                        > */}
                                  <i
                                    className="fas fa-database"
                                    title={`Source: ${doc.doc_source}`}
                                  />
                                  {/* </OverlayTrigger> */}
                                </div>
                              )}
                              {isChannelPartner === "1" ? null : (
                                pursuitView && ISEditable(doc.file_type) && (
                                  <div className="col-2 ps-0">
                                    <a
                                      href={
                                        "javascript:AceBrowser.openWindowModeless('" +
                                        GetPursuitUrl(doc.url) +
                                        "','width=1200px;height=800px;');"
                                      }
                                    >
                                      {/* <i className="fas fa-copy pointer" title="Re-Use" /> */}
                                    </a>
                                  </div>
                                ))}

                            </div>

                          </div>
                          {isChannelPartner === "1" ? null : (
                            <div className="col-1 mt-1">
                              {/*  star rating starts*/}
                             
                              {/* star rating ends*/}
                              {/* comment from here */}
                              {/* <div className="row like_align">
                      <span align="" className="col-6 pl-1">
                        <div align="center">
                          {isLikeDislike(feedbacks, doc.id, true) ? (
                            <i
                              className="far fa-thumbs-up like-active disable fa"
                              id={"up" + doc.id}
                              title="Liked"
                            />
                          ) : (
                            <i
                              className="far fa-thumbs-up pointer"
                              id={"up" + doc.id}
                              title="Like"
                              onClick={() => {
                                setDocLike({
                                  comment: "",
                                  isLiked: true,
                                  docID: doc.id,
                                });
                                handleModalOpen(DOC_LIKE);
                              }}
                            />
                          )}
                          <br />
                          <GetLikes
                            documentID={doc.id}
                            isLiked={true}
                            feedbacks={userDetails.userFeedbacks}
                          />
                        </div>
                      </span>
                      <span className="col-6 pl-1">
                        <div align="center">
                          {isLikeDislike(feedbacks, doc.id, false) ? (
                            <i
                              className="far fa-thumbs-down like-down fa"
                              id={"down" + doc.id}
                              title="Disliked"
                            />
                          ) : (
                            <i
                              className="far fa-thumbs-down pointer"
                              id={"down" + doc.id}
                              title="Dislike"
                              onClick={() => {
                                setDocLike({
                                  comment: "",
                                  isLiked: false,
                                  docID: doc.id,
                                });
                                handleModalOpen(DOC_LIKE);
                              }}
                            />
                          )}
                          <br />
                          <GetLikes
                            documentID={doc.id}
                            isLiked={false}
                            feedbacks={userDetails.userFeedbacks}
                          />
                        </div>
                      </span>
                    </div> */}
                              {/* comment till 718 */}
                            </div>
                          )}

                        </div>
                        <div className="col-12 row me-0 ps-2">
                          <div className="col-1 pe-0"></div>
                          <div className="col-10 ps-0">
                           {doc.disclosure_level && <span className="fontx9 me-2 badge-doctype" align="left">
                              {doc.disclosure_level}
                            </span>}
                            {doc.modified_date ? (
                              <span className="fontx9" align="left">
                                <strong>Modified on: </strong>
                                {doc.modified_date.split("T") &&
                                  doc.modified_date.split("T")[0] &&
                                  doc.modified_date.split("T")[0]}
                              </span>
                            ) : doc.sysmodtimestamp ? (
                              <span className="fontx9" align="left">
                                <strong>Modified on: </strong>
                                {doc.sysmodtimestamp.split("T") &&
                                  doc.sysmodtimestamp.split("T")[0] &&
                                  doc.sysmodtimestamp.split("T")[0]}
                              </span>) : " "
                            }
                          </div>
                        </div>
                      </div>
                    )
                ))}

            {/* Document Preview Modal */}
            {modal[DOC_PREVIEW] && (
              <Modal
                show={modal[DOC_PREVIEW]}
                onHide={() => handleModalClose(DOC_PREVIEW)}
                dialogClassName="preview-modal document-preview-modal"
                animation={false}
              >
                <Modal.Header>
                  <Modal.Title>Document Preview</Modal.Title>
                  <button
                    type="button"
                    translate="no"
                    onClick={() => handleModalClose(DOC_PREVIEW)}
                    class="btn-close"
                    data-bs-dismiss="modal"
                  >
                    {/* <i className="fa fa-times" style={{ fontSize: "15px" }}></i> */}
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <div className="preview_iframe document-preview_iframe">
                    <iframe frameborder="0" allowfullscreen src={previewURL.url} />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => handleModalClose(DOC_PREVIEW)}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            )}

            {/* DocShareModal */}
            {modal[DOC_SHARE] && (
              <Modal
                show={modal[DOC_SHARE]}
                onHide={() => handleModalClose(DOC_SHARE)}
                centered
                animation={false}
              >
                <Modal.Header>
                  <Modal.Title>Share Document</Modal.Title>
                  <button
                    type="button"
                    translate="no"
                    onClick={() => handleModalClose(DOC_SHARE)}
                    class="btn-close"
                    data-bs-dismiss="modal"
                  >
                    {/* <i className="fa fa-times" style={{ fontSize: "15px" }}></i> */}
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <div className="col-12" align="center">
                    <div className="d-flex p-0 pb-2">
                      {/* <div className="col-4" > */}
                      <i className="fas fa-share-alt me-2 ms-2 mtop2" /> Share Via
                      Knowledge Management Center
                      {/* </div> */}
                      {/* <div className="col-8">
                  <div className="row">
                    <input
                      type="radio"
                      name="viaemail"
                      className="form-check-input"
                      value="true"
                      onChange={handleCheckChange}
                      checked={docShare.via !== "" ? docShare.via : false}
                    />
                    <span>Share Via Email</span>
                  </div>
                  <div className="row">
                    <input
                      type="radio"
                      name="viadelta"
                      className="form-check-input"
                      value="false"
                      // onChange={handleCheckChange}
                      // checked={docShare.via !== "" ? !docShare.via : false}
                      checked="true"
                    />
                    <span>Share Via Knowledge Management Center</span>
                  </div>
                </div> */}
                    </div>
                    <div id="emailbox" className="col-12 row">
                      {/* {docShare.via !== "" && ( */}
                      <>
                        <div className="form-group col-12 ps-2">
                          <ReactMultiEmail
                            placeholder="Enter only valid HPE Email Ids"
                            emails={docShare.emails}
                            onChange={(_emails) => {
                              handleMultiEmailChange(_emails);
                            }}
                            validateEmail={(email) => {
                              return checkEmailValidity(email); // return boolean
                            }}
                            getLabel={(email, index, removeEmail) => {
                              return (
                                <div data-tag key={index}>
                                  {email}
                                  <i
                                    data-tag-handle
                                    className="fas fa-times"
                                    onClick={() => removeEmail(index)}
                                  />
                                </div>
                              );
                            }}
                          />
                          {errData.email && (
                            <div className="row ps-4 text-danger">
                              {errData.email}
                            </div>
                          )}
                          {errData.emails &&
                            errData.emails.length > 0 &&
                            map(errData.emails, (email) => (
                              <div className="row ps-4 text-danger">
                                "{email}" is an Invalid User
                              </div>
                            ))}
                          {errData.guest &&
                            errData.guest.length > 0 &&
                            map(errData.guest, (email) => (
                              <div className="row ps-4 text-warning">
                                "{email}" is a Guest User
                              </div>
                            ))}
                          {checkEmail && <div className="row ps-4">Validating mail please wait ...</div>}
                        </div>
                      </>
                      {/* )} */}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={() => handleShare(DOC_SHARE)}
                    disabled={
                      button[DOC_SHARE] ||
                      (docShare.emails && docShare.emails.length <= 0) ||
                      errData.email ||
                      (errData.emails && errData.emails.length > 0)
                    }
                  >
                    Share
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => handleModalClose(DOC_SHARE)}
                    disabled={
                      button[DOC_SHARE] &&
                      docShare.emails &&
                      docShare.emails.length > 0
                    }
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            )}

            {/* DocUpdateModal */}
            {modal[DOC_UPDATE] && (
              <Modal
                show={modal[DOC_UPDATE]}
                onHide={() => handleModalClose(DOC_UPDATE)}
                centered
                animation={false}
              >
                <Modal.Header>
                  <Modal.Title>Update Request Form</Modal.Title>
                  <button
                    type="button"
                    translate="no"
                    onClick={() => handleModalClose(DOC_UPDATE)}
                    class="btn-close"
                    data-bs-dismiss="modal"
                  >
                    {/* <i className="fa fa-times" style={{ fontSize: "15px" }}></i> */}
                  </button>
                </Modal.Header>
                <Modal.Body>
                  {loader ? (
                    <p>Loading...</p>
                  ) : (
                    <div className="col-12 pe-0" id="updaterequest">
                      <div className="col-12 row pe-0 me-0">
                        <div className="row col-12">
                          <div className="col-6 ps-0 mb-2 ps-4">Update Request</div>
                          <div className="col-6 mb-2">
                            <select
                              required
                              className="form-control form-control-sm"
                              onChange={handleDocUpdate}
                              name="updateRequest"
                              value={docUpdate.updateRequest}
                            >
                              {map(docUpdateOptions, (item) => (
                                <option value={item.name}>{item.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-12 row">
                          <div className="col-6 ps-0  ps-4">Additional Comments</div>
                          <div className="col-6">
                            <textarea
                              className="form-control"
                              id="addcomments"
                              rows="2"
                              onChange={handleDocUpdate}
                              name="addComments"
                              value={docUpdate.addComments}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={() => handleDocUpdateSave(DOC_UPDATE)}
                    disabled={button[DOC_UPDATE]}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => handleModalClose(DOC_UPDATE)}
                    disabled={button[DOC_UPDATE]}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            )}

            {/* MetaDetailsModal Starts */}
            {modal[DOC_META] && (
              <Modal
                show={modal[DOC_META]}
                onHide={() => handleModalClose(DOC_META)}
                centered
                animation={false}
              >
                <Modal.Header>
                  <Modal.Title>Document Metadata</Modal.Title>
                  <button
                    type="button"
                    translate="no"
                    onClick={() => handleModalClose(DOC_META)}
                    class="btn-close"
                    data-bs-dismiss="modal"
                  >
                    {/* <i className="fa fa-times" style={{ fontSize: "15px" }}></i> */}
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <div id="matadata" className="col-12">
                    <div className="row col-12 mb-2">
                      <span>
                        <b>{`File Name: `}</b>
                        {`${docMeta.fileName}`}
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-6 ps-0">
                        {/* <ul className="list-group list-group-flush">
                      {map(LIST_1, (value, key) => (
                        <li className="list-group-item metadata_items">
                          <b>{`${startCase(key)}: `}</b>
                          {`${docMeta.data[value]}`}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-6 pl-0">
                    <ul className="list-group list-group-flush">
                      {map(LIST_2, (value, key) => (
                        <li className="list-group-item metadata_items">
                          <b>{`${startCase(key)}: `}</b>
                          {`${docMeta.data[value]}`}
                        </li>
                      ))}
                    </ul> */}
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item metadata_items">
                            <b>Document ID: </b>
                            {docMeta.data.id}
                          </li>
                          <li className="list-group-item metadata_items">
                            <b>Document Type: </b>
                            {docMeta.data.document_type}
                          </li>
                          <li className="list-group-item metadata_items">
                            <b>Description: </b>
                            {docMeta.data.description}
                          </li>
                          <li className="list-group-item metadata_items">
                            <b>Disclosure: </b>
                            {docMeta.data.disclosure_level}
                          </li>
                        </ul>
                      </div>

                      <div className="col-6 p-0">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item metadata_items">
                            <b>Language: </b>
                            {docMeta.data.language_s}
                          </li>
                          <li className="list-group-item metadata_items">
                            <b>Keywords: </b>
                            {docMeta.data.keywords}
                          </li>
                          <li className="list-group-item metadata_items">
                            <b>Created Date (UTC): </b>
                            {docMeta.data.creation_date.split("T")[0] || ""}
                          </li>
                          <li className="list-group-item metadata_items">
                            <b>Modified Date (UTC): </b>
                            {docMeta.data.modified_date.split("T")[0] || ""}
                          </li>
                          <li className="list-group-item metadata_items">
                            <b>Source: </b>
                            {docMeta.data.source}
                          </li>
                          <li className="list-group-item metadata_items">
                            <b>Version: </b>
                            {docMeta.data.version}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => handleModalClose(DOC_META)}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            )}

            {/* Like/Dislike Modal */}
            {modal[DOC_LIKE] && (
              <Modal
                show={modal[DOC_LIKE]}
                onHide={() => handleModalClose(DOC_LIKE)}
                animation={false}
                centered
              >
                <Modal.Header>
                  <Modal.Title>{`${docLike.isLiked ? "Like" : "Dislike"
                    } Document`}</Modal.Title>
                  <button
                    type="button"
                    translate="no"
                    onClick={() => handleModalClose(DOC_LIKE)}
                    class="btn-close"
                    data-bs-dismiss="modal"
                  >
                    {/* <i className="fa fa-times" style={{ fontSize: "15px" }}></i> */}
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <div className="col-12">
                    <div className="col-12 row pb-2">
                      <div className="col-12">
                        {`Please provide your feedback ${docLike.isLiked ? "(optional)" : "(required)"
                          }`}
                      </div>
                    </div>
                    <div id="emailbox" className="col-12 row">
                      <div className="form-group col-12 ps-2">
                        <textarea
                          className="form-control"
                          id="comment"
                          onChange={handleCommentChange}
                          value={docLike.comment || ""}
                          placeholder="Enter Feedback"
                          rows="3"
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => handleLikeDislike()}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => handleModalClose(DOC_LIKE)}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            )}

            {/* CommentModal */}
            {modal[DOC_COMMENT] && (
              <Modal
                show={modal[DOC_COMMENT]}
                onHide={() => handleModalClose(DOC_COMMENT)}
                animation={false}
                centered
              >
                <Modal.Header>
                  <Modal.Title>Document Comments</Modal.Title>
                  <button
                    type="button"
                    translate="no"
                    onClick={() => handleModalClose(DOC_COMMENT)}
                    class="btn-close"
                    data-bs-dismiss="modal"
                  >
                    {/* <i className="fa fa-times" style={{ fontSize: "15px" }}></i> */}
                  </button>
                </Modal.Header>
                <Modal.Body> Document Preview Content</Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => handleModalClose(DOC_COMMENT)}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            )}

          </>
        )}
    </div>
  );
};

export default Documents;
