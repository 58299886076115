import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Accordion } from "react-bootstrap";
import ChatBotAccordion from "./ChatBotAccordion";
import ReactPlayer from "react-player";
import { Modal } from "react-bootstrap";

export default function ChatBotCarousel(props) {
  const data = props.data.other_responses;
  const [showCarousel, setShowCarousel] = useState(true);
  const [videoPlayer, setVideoPlayer] = useState(false);
  const [videoTitle, setVideoTitle] = useState([]);
  const [videoUrl, setVideoURL] = useState([]);
  const handleDocPreview = (docUrl) => {
    if (docUrl.indexOf("8082") > 0) props.handlePreview(docUrl);
    else window.open(docUrl, "_blank");
    // console.log("docccc", docUrl);
  };
  const handleCheckURL = (docUrl, docTitle) => {
    if (docUrl.includes(".mp4")) {
      setVideoPlayer(true);
      setVideoURL(docUrl);
      setVideoTitle(docTitle);
    } else {
      handleDocPreview(docUrl);
    }
  };
  const handleClose = () => setVideoPlayer(false);

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    height: 30,
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
  };

  const indicatorStyles = {
    background: "#fff",
    cursor: "pointer",
    height: "8px",
    width: "8px",
    margin: "0 2px",
    borderRadius: "50%",
    display: "inline-block",
    transition: "background-color 0.6s ease",
  };

  const scrollClick = () => {
    setTimeout(() => {
      document
        .getElementById(props.data.chat_id)
        .scrollIntoView({ block: "end" });
    }, 500);
  };
  // useEffect(()=>{
  //   setTimeout(()=>{
  //     document.getElementById(props.data.chat_id).scrollIntoView({behavior:"smooth"});
  //   },500)
  // },[props.data.chat_id])
  return (
    <div className="App">
      <Accordion key={props.data.chat_id} onClick={scrollClick}>
        <ChatBotAccordion
          eventKey={`${props.data.chat_id}`}
          message={props.data.message}
        ></ChatBotAccordion>
        <Accordion.Collapse
          eventKey={`${props.data.chat_id}`}
          style={{ borderBottom: 0 }}
        >
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                padding: "15px 10px 0 10px",
              }}
              class="chatbot-carousel"
              id={`${props.data.chat_id}`}
            >
              <Carousel
                style={{ maxWidth: "320px", maxHeight: "180px" }}
                showStatus={false}
                infiniteLoop={true}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <div
                      onClick={onClickHandler}
                      style={{ ...arrowStyles, left: 15 }}
                    >
                      {" "}
                      {`❮`}{" "}
                    </div>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <div
                      onClick={onClickHandler}
                      style={{ ...arrowStyles, right: 15 }}
                    >
                      {" "}
                      {`❯`}{" "}
                    </div>
                  )
                }
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  if (isSelected) {
                    return (
                      <li
                        style={{
                          ...indicatorStyles,
                          backgroundColor: "#717171",
                        }}
                        aria-label={`Selected: ${label} ${index + 1}`}
                        title={`Selected: ${label} ${index + 1}`}
                      />
                    );
                  }
                  return (
                    <li
                      style={{ ...indicatorStyles, backgroundColor: "#bbb" }}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      title={`${label} ${index + 1}`}
                      aria-label={`${label} ${index + 1}`}
                    />
                  );
                }}
              >
                {data.map((item, index) => (
                  <div
                    key={"slide" + index}
                    style={{
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      height: 150,
                      maxWidth: "320px",
                      maxHeight: "180px",
                    }}
                  >
                    <div
                      class="chatbot-carousel-title"
                      style={{
                        paddingBottom: 25,
                        paddingLeft: "10%",
                        paddingRight: "10%",
                      }}
                    >
                      {item.title}
                    </div>
                    <div
                      style={{ padding: "20px 15%" }}
                      class="chatbot-carousel-url"
                      onClick={() => handleCheckURL(item.url, item.title)}
                    >
                      {item.url}
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </Accordion.Collapse>
      </Accordion>
      <Modal
        show={videoPlayer}
        onHide={handleClose}
        dialogClassName="modal-90w"
        centered
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>{videoTitle}</Modal.Title>
          <button
            type="button"
            translate="no"
            onClick={() => handleClose()}
            class="btn-close"
            data-dismiss="modal"
          >
            {/* <i className="fa fa-times" style={{ fontSize: "15px" }}></i> */}
          </button>
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer
            url={videoUrl}
            controls={true}
            width="1100"
            height="360"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
