import React from 'react'
import TrackingService from '../TrackingService';
import { useEffect, useState } from "react";
import {
  Widget,
  addResponseMessage,
  addLinkSnippet,
  toggleMsgLoader,
  renderCustomComponent,
  setQuickButtons,
} from "react-chat-widget";
import axios from "axios";
import Cookies from "js-cookie";
import botAvatar from "../img/botAvatar.png";
import userAvatar from "../img/userAvatar.jpg";
import CryptoJS from "crypto-js";
import "react-chat-widget/lib/styles.css";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ChatBotFeedback from "../Components/ChatBot/ChatBotFeedback";
import ChatBotCarousel from "../Components/ChatBot/ChatBotCarousel";
import ChatBotHeadImg from "../img/dot-head.png";
import ChatBotFullImg from "../img/dot-full.png";
import ChatBotCloseImg from "../img/Chatbot_Close.png"

const DOC_PREVIEW = "docPreview";
const trackingService = new TrackingService();
const ChannelPartnerChatBot = (props) => {
    const [previewURL, setPreviewURL] = useState([{ url: "", doc_Link: "" }]);
    const [isChatBotOpened , setIsChatBotOpened] = useState(false);

    const [modal, setModal] = useState({
        [DOC_PREVIEW]: false,
      });
      const button = [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ];
      const [isMultipleResponse, setIsMultipleResponse] = useState(false);
      const [message, setMessage] = useState("For more click here");
      const [data_, setData_] = useState([]);

      useEffect(() => {
        if (Cookies.get("name") !== undefined && props.loadBot === false) {
          addResponseMessage(
            "Hello " +
              Cookies.get("name").split(' ').slice(0, -1).join('') +
              ", Thanks for choosing to chat with me."
          );
        }
      }, [Cookies.get("name")]);

      const submitFeedback = (feedbackDetails) => {
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_CHATBOTAPI_CP}feedbacks`,
          headers: {
            "Content-Type": "application/json",
          },
          data: feedbackDetails,
        };
    
        axios(config).then(function (response) {});
        addResponseMessage("Thanks for submitting the feedback");
      };

      const handleNewUserMessage = (newMessage) => {
        console.log(newMessage);
        console.log(`New message incoming! ${newMessage}`);
        console.log(Cookies.get("empnumber"), "chatbot", newMessage);
       
    
        trackingService.LogChatbotClick(Cookies.get("empnumber"), newMessage);
        var data = JSON.stringify({
          message: newMessage,
          employee_id: Cookies.get("empnumber"),
          persona: Cookies.get("roles"),
        });
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_CHATBOTAPI_CP}deltabot`,
    
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        toggleMsgLoader();
        axios(config).then(function (response) {
          toggleMsgLoader();
          if (response.data) {
            console.log(response.data);
            console.log(response.data.other_responses);
            if (response.data.answer.split("href").length > 1) {
              const title = response.data.answer
                .split("href")[0]
                .replace("<b><u><a ", "");
              const url = response.data.answer
                .split("href")[1]
                .replace('="', "")
                .split('"')[0];
    
              renderCustomComponent(ChatBotFeedback, {
                title,
                url,
                chatId: response.data.chat_id,
                handlePreview,
                submitFeedback,
              });
              
            } else {
              const title = response.data.answer;
              renderCustomComponent(ChatBotFeedback, {
                title,
                submitFeedback,
              });
            }
            if (response.data.message)
              renderCustomComponent(ChatBotCarousel, {
                data: response.data,
                handlePreview,
              });
            setData_(response.data.other_responses);
          }
        });
      };
      const handlePreview = (url) => {
        var filepath =
          "F:\\AnPS\\Sharepointfiles\\" +
          url
            .replace("https://hpedelta.com:8082/", "")
            .replace(/ /g, "%20")
            .split("/")
            .join("\\");
        filepath = CryptoJS.enc.Utf8.parse(filepath);
        filepath = CryptoJS.enc.Base64.stringify(filepath);
        const previewURL = "https://hpedelta.com/Viewer.aspx?file=" + filepath;
        console.log(previewURL);
        setPreviewURL((prevState) => ({
          ...prevState,
          url: previewURL,
          doc_Link: url,
        }));
        handleModalOpen(DOC_PREVIEW);
      };
      const handleModalOpen = (item) => {
        console.log(Cookies.get("empnumber"), "chatbot");
        setModal((prevState) => ({ ...prevState, [item]: true }));
      };
      const handleModalClose = (item) => {
        setModal((prevState) => ({ ...prevState, [item]: false }));
      };
      const logChatbot = () => {};
    
      const handleChatToggle = (status) => 
        setIsChatBotOpened(status)

  return (
    <>
    <Widget
      handleNewUserMessage={handleNewUserMessage}
      showTimeStamp={false}
      toggleMsgLoader={true}
      title={"Delta Chat support"}
      subtitle={"Hi, My name is Dot. How can I help you?"}
      profileAvatar={botAvatar}
      profileClientAvatar={userAvatar}
      launcherOpenImg={ChatBotFullImg}
      launcherCloseImg={ChatBotCloseImg}
      handleToggle={handleChatToggle}
    />
   
    {isChatBotOpened && 
      <div className="rcw-widget-container-bot-img"><img className="" src={ChatBotHeadImg}></img></div>
    }
    {/* Document Preview Modal */}
    {modal[DOC_PREVIEW] && (
      <Modal
        show={modal[DOC_PREVIEW]}
        onHide={() => handleModalClose(DOC_PREVIEW)}
        dialogClassName="preview-modal"
        onClick={logChatbot}
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>Document Preview</Modal.Title>
          <button
            type="button"
            translate="no"
            onClick={() => handleModalClose(DOC_PREVIEW)}
            class="btn-close"
            data-dismiss="modal"
          >
            {/* × */}
          </button>
        </Modal.Header>
        <Modal.Body dialogClassName="preview-body">
          <div className="preview_iframe">
            <iframe frameborder="0" allowfullscreen src={previewURL.url} />
          </div>
        </Modal.Body>
      </Modal>
    )}
  </>
  )
}

export default ChannelPartnerChatBot
