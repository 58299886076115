import React, { useEffect, useState } from 'react'
import hpeLogo from "../img/ChannelPartner/hpe_logo.png";
import Cookies from "js-cookie";
import { Accordion, Card, CloseButton, Modal, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TrackingService from "../TrackingService";
import axios from "axios";
import Avatar from 'react-avatar';

  const trackingService = new TrackingService();
const ChannelPartnerHeader = ({onSubmit,setActiveFilter,
    onCustomerSearchFormSubmit,
    onSearchFormSubmit, 
    user,
    dispatch,}) => {
      const signal = axios.CancelToken.source();
      const navigate = useNavigate();


     
  return (
    <>
    {window.location.pathname ==="/search1"?(
      <header>
     <nav class="navbar navbar-expand-lg">
    <div className="d-flex flex-row align-items-center ps-4 fs-3 w-100">
      <Navbar.Brand href="/">
        <img
          // style={{ marginRight: "20px" }}
          src={hpeLogo}
          width="100"
          height="50"
          alt=""
        />{" "}
      </Navbar.Brand>

      <div class="collapse navbar-collapse" id="main_nav">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a
                class="nav-link text-dark"
                style={{ fontWeight: "700" }}
                // href="#"
              >
                Welcome {Cookies.get("name")?.split(" ")[0]}, to the Digital Library !
              </a>
            </li>
          </ul>
          </div>
    </div>
    {/* </Container> */}
    <div className="d-flex justify-content-center align-items-center pe-3">
      
      <Nav className="ms-3">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={
            <Tooltip className=" homepage-profile-tooltip">
              <>
                <div>
                  {Cookies.get("empnumber") !== undefined
                    ? Cookies.get("name")
                    : "User"}
                </div>
                {/* <div style={{ fontSize: "10px" }}>
                  {Cookies.get("roles")
                    ? Cookies.get("roles").split(",").join(", ")
                    : "Guest"}
                </div> */}
              </>
            </Tooltip>
          }
        >
          <div  style={{marginRight:"5vw"}}>
            <Avatar
              // onClick={() => handleClickUserProfile()}
              className="homepage-profile-avatar"
              color={Avatar.getRandomColor("sitebase", [
                "red",
                "green",
                "blue",
              ])}
              name={
                Cookies.get("empnumber") !== undefined
                  ? Cookies.get("name")
                  : "User"
              }
              title=""
              size="35"
              round
            />
          </div>
        </OverlayTrigger>
      </Nav>
    </div>
  </nav>
  </header>):( <>
  <Navbar
    bg="white"
    variant="light"
    className="navbar-centered"
    style={{ height: "100%" }}
  >
    <div className="d-flex flex-row align-items-center ps-4 fs-3 w-100">
      <Navbar.Brand href="/">
        <img
          src={hpeLogo}
          width="100"
          height="50"
          alt=""
        />{" "}
      </Navbar.Brand>

      <div className="text-dark"  style={{ fontWeight: "700" }}>
          <h5 className='welcomeBanner'>
          Welcome {Cookies.get("name")?.split(" ")[0]}, to Knowledge management Center
          </h5>
      </div>
    </div>
    <div className="d-flex justify-content-center align-items-center pe-3">
      <Nav className="ms-3">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={
            <Tooltip className=" homepage-profile-tooltip">
              <>
                <div>
                  {Cookies.get("empnumber") !== undefined
                    ? Cookies.get("name")
                    : "User"}
                </div>
                {/* <div style={{ fontSize: "10px" }}>
                  {Cookies.get("roles")
                    ? Cookies.get("roles").split(",").join(", ")
                    : "Guest"}
                </div> */}
              </>
            </Tooltip>
          }
        >
          <div>
            <Avatar
              // onClick={() => handleClickUserProfile()}
              className="homepage-profile-avatar"
              color={Avatar.getRandomColor("sitebase", [
                "red",
                "green",
                "blue",
              ])}
              name={
                Cookies.get("empnumber") !== undefined
                  ? Cookies.get("name")
                  : "User"
              }
              title=""
              size="35"
              round
            />
          </div>
        </OverlayTrigger>
      </Nav>
    </div>
  </Navbar>
</>)}
   
 
</>
  )
}

export default ChannelPartnerHeader
