export class FeedbackDetails {
  constructor() {
    this.FeedbackId = "";
    this.Type = 0;
    this.Category = 0;
    this.Description = "";
    this.Attachment = "";
    this.AcknowledgementEmailSent = false;
    this.UserId = "";
    this.UserName = "";
    this.UserEmail = "";
    this.CreatedOn = "10-10-2021";
    this.LastmodifiedOn = "10-10-2021";
    this.Isactive = true;
    this.Isresolved = false;
    this.ResolvedBy = "";
    this.ResolvedOn = "";
    this.AttachmentIds= "";
  }
}