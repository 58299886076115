import React, { Component } from "react";
import "bootstrap";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import URLConfig from "../URLConfig";
import logo from "../img/loading-icon-animated.gif";
import TrackingService from "../TrackingService";
import UseFulLinks from "./UseFullLinks";
// import Tips from "./Tips";
import { UserContext } from "../Context/UserContext";
import KMNuggets from "./KMNuggets";
import Maintainance from "./Maintenance/UnscheduledMaintenance";
//import { withRouter } from "react-router";
import Feedback from "./Feedback/Feedback";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
//import HeaderFormRedesign from "./HeaderFormRedesign";
import { CloseButton } from "react-bootstrap";
//import GuestPersona from "./UserProfile/GuestPersona";
//import CustomDialogPopup from "./Common/CustomDialogPopup";
import DeltaHomeContext from "../Context/DeltaHomeContext";
import { Outlet } from "react-router-dom";
import ChannelPartnerHeader from "./ChannelPartnerHeader";
import ChannelPartnerChatBot from "./ChannelPartnerChatBot";
class Home extends React.Component {
  static contextType = DeltaHomeContext;
  constructor(props) {
    super(props);
    window.callbackPursuit = this;
    this.state = {
      hasTouched: false,
      SearchInitiated: false,
      isDeltaUser: true,
      isMovedAlready: false,
      createdFromDate: new Date(),
      createdToDate: new Date(),
      publishedFromDate: new Date(),
      publishedToDate: new Date(),
      lastModifiedFromDate: new Date(),
      lastModifiedToDate: new Date(),
      tooltipshow: true,
      AccountSTID: "",
      selectedCategory: [],
      isTemplateEnable: false,
      showFooter: false,
      invokeGetMappingProfileforRestriction: false,
      peopleCapsuleData: null,
      showDeliveryMixModal: false,
      daysleft: 0,
      showFeatureGuide: false,
      loader: true,
      userRole : Cookies.get("roles"),
      showGuestPersonaModal : false,
      isBotLoaded : false
    };
    this.boxRef = React.createRef();
  }
  getMappingProfileforRestriction; // };

  componentDidMount = () => {
    this.TrackingService = new TrackingService();
    console.log(" componentDidMount");
    this.setState({
      invokeGetMappingProfileforRestriction: true,
    });
    //this.context.getMappingProfileforRestriction();
    // this.getAllMasterData();
    document.addEventListener(
      "contextmenu",
      (e) => {
        e.preventDefault();
      },
      false
    );
    this.blinkMe();
    this.getRemainingDays();

    if(localStorage.getItem("roles") == "Guest" && localStorage.getItem("isChannelPartner") == 0) {
      this.setState({showGuestPersonaModal : true});
    }    

    this.setState({isBotLoaded : true});
  };

  componentDidUpdate = ()=>{
    if(window.location.pathname ==="/" && document.getElementById("SearchAutoSuggestionsPrimary")?.hidden === true &&  document.getElementById("content").hidden === true){
      document.getElementById("SearchAutoSuggestionsPrimary").hidden = false;
      document.getElementById("content").hidden = false;
    }
    if (this.state.invokeGetMappingProfileforRestriction) {
      console.log("componentDidUpdate if if");
      // this.context.getMappingProfileforRestriction();
      this.setState({
        invokeGetMappingProfileforRestriction: false,
      });
    }
  };

  hideGuestPersonaModal = () => {
    this.setState({ showGuestPersonaModal: false });
  };
  blinkMe = () => {
    setTimeout(() => {
      this.setState({ tooltipshow: false });
    }, 5000);
    return false;
  };
  getRemainingDays = () => {
    var countDownDate = new Date("Apr 16, 2022 00:00:00").getTime();
    var now = new Date().getTime();
    var timeleft = countDownDate - now;
    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    this.setState({ daysleft: days });
  };

  MoveSearchElements = () => {
    this.context.hidePractice();
    this.setState({ SearchInitiated: true, isMovedAlready: true });
    if (document.getElementById("SearchAutoSuggestions"))
      if (
        document.getElementById("SearchAutoSuggestionsPrimary")?.children.length
      ) {
        // document.getElementsById("content")[0].hidden = true;
        // document.getElementsByClassName("main-container")[0].hidden = true;
        // document.getElementsByClassName("header-logo-only-hp")[0].hidden = true;
        if (
          document
            .getElementsByClassName("header-logo-with-anps")[0]
            ?.classList.contains("display-none")
        )
          document
            .getElementsByClassName("header-logo-with-anps")[0]
            .classList.toggle("display-none");
        document.body.classList.toggle("body-grey-background");
        if (document.getElementsByClassName("usefullinks")[0])
          document.getElementsByClassName("usefullinks")[0].hidden = true;
        if (
          document
            .getElementsByClassName("search-base")[0]
            .classList.contains("display-none")
        )
          document
            .getElementsByClassName("search-base")[0]
            .classList.toggle("display-none");
        document.getElementById("searchBtn").hidden = true;
      }
  };


  onDeliveryMixPdfClose = () => {
    this.setState({
      showDeliveryMixModal: !this.state.showDeliveryMixModal,
    });
  };

  archiveActiveValue = (activearchivevalue) => {
    this.setState(
      {
        archiveActive: activearchivevalue,
      },
      () => {}
    );
  };

  showHideFeatureGuide = (e) => {
    e.preventDefault();
    this.setState({
      showFeatureGuide: !this.state.showFeatureGuide,
    });
  };

  render() {
    const Roles = Cookies.get("roles")?.split(",");
    const envData = URLConfig.get_Env(); // get the application environment (UAT/PROD)
    const isChannelPartner = localStorage.getItem("isChannelPartner");

    // test comment
    return (
      <>
        <UserContext.Consumer>
          {([user, dispatch]) => {
            return (
              <>

                {
                (window.location.pathname ==="/search" || window.location.pathname ==="/search1") && isChannelPartner === "1" ?null:
                isChannelPartner === "1"? 
                (
                <ChannelPartnerHeader
                onSubmit={this.context.onSearchFormSubmit}
                user={user}
                dispatch={dispatch}
                /> ): (
                  <></>
              // <HeaderFormRedesign
              //   user={user}
              //   dispatch={dispatch}
              //   SearchInitiated={this.state.SearchInitiated}
              //   updateRoles={this.context.UpdateRoles}
              //   filters={this.context.filters}
              //   SelectedFilters={this.context.SelectedFilters}
              //   onSubmit={this.context.onSearchFormSubmit}
              //   hasTouched={this.state.hasTouched}
              //   searchTerm={this.context.searchTerm}
              //   toggleDocDepo={this.context.toggleDocDepo}
              //       UpdateUserCategoryMapping={
              //         this.context.UpdateUserCategoryMapping
              //       }
              //   // showFeedback={this.showFeedback}
              //   // toggleDocDepoFun={this.toggleDocDepoFun}
              //   // showDocDepo = {this.showDocDepo}
              //   // showHideDivProjects = {this.showHideDivProjects}
              // />
                )}
              </>
            );
          }}
        </UserContext.Consumer>
        {this.context.showOutageTemplate && <Maintainance />}
        <Outlet />

        {isChannelPartner !== "1" &&
          // !this.context.MasterData &&
          this.context.toggleDocDepo &&
          !this.context.showDocAnalysisModule && (
            <div className="text-center">
              <img className="loading-img" src={logo} alt="loading"></img>
            </div>
          )}


        {!this.context.toggleDocDepo && !this.context.showOutageTemplate && (
          <div
            id="maincontainer"
            className="container-fluid bg-imgcss main-container"
          >
            <div id="topbtns" className="col-12 row pt-1">
              <div
                id="practiceProjectDic_"
                className="capsulefiltermain"
                ref={this.boxRef}
              >
                <>
                  <div className="container mt-2 p-0">
                    <div className="accordion" id="RightSideBarAccordion">
                      <div className="card" style={{ textAlign: "left" }}>
                        <div className="card-headerremove" id="exItem2Header">
                          <h5 className="mb-0">
                            <button
                              className="collapsed btn btn-sm practicebtn"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#exItem2"
                              aria-expanded="false"
                              aria-controls="exItem2"
                            >
                              My Projects
                            </button>
                          </h5>
                        </div>
                      </div>

                      <div className="card" style={{ textAlign: "left" }}>
                        <div className="card-headerremove" id="exItem3Header">
                          <h5 className="mb-0">
                            <button
                              className="collapsed btn btn-sm practicebtn"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#exItem3"
                              aria-expanded="false"
                              aria-controls="exItem3"
                              onClick={this.context.logMyContribution}
                            >
                              My Contribution
                            </button>
                          </h5>
                        </div>
                        <div
                          id="exItem3"
                          className="collapse"
                          aria-labelledby="exItem3Header"
                          data-bs-parent="#RightSideBarAccordion"
                        >
                          <div className="card-body ms-3 mb-2">
                            {/* <HeaderCounts /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
            <div id="topbtns" className="col-12 row pt-1">
              <div
                id="practiceProjectDic"
                className="capsulefiltermain"
                ref={this.boxRef}
              >
                <>
                  <div className="container mt-2 p-0">
                    <div className="accordion" id="RightSideBarAccordion">
                      {/* KM VIDEOS */}
                      <div className="card" style={{ textAlign: "left" }}>
                        <div className="card-headerremove" id="exItem4Header">
                          <h5 className="mb-0">
                            <button
                              className="collapsed btn btn-sm practicebtn"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#exItem4"
                              aria-expanded="false"
                              aria-controls="exItem4"
                            >
                              Knowledge Videos
                            </button>
                          </h5>
                        </div>
                        <div
                          id="exItem4"
                          className="collapse"
                          aria-labelledby="exItem4Header"
                          data-bs-parent="#RightSideBarAccordion"
                        >
                          <div className="card-body ms-3 mb-2">
                            <KMNuggets />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>

            <div>
              {this.context.showFeedbackform && (
                <Modal
                  show={this.context.showFeedbackform}
                  onHide={this.context.showFeedback}
                  dialogClassName="deltaFeedbackModal"
                  animation={false}
                >
                  <Modal.Body bsPrefix="document-data-modal">
                    <Feedback
                      onFeedbackFormClose={this.context.onFeedbackFormClose}
                      closeFeedback={this.context.closeFeedback}
                    />
                  </Modal.Body>
                </Modal>
              )}
              {/* {this.state.showGuestPersonaModal && (
                <GuestPersona
                  showGuestPersonaModal={this.state.showGuestPersonaModal}
                  hideGuestPersonaModal={this.hideGuestPersonaModal}
                />
              )} */}
            </div>
          </div>
        )}
         {this.context.toggleDocDepo && this.context.showFeedbackform && (
            <Modal
              show={this.context.showFeedbackform}
              onHide={this.context.showFeedback}
              dialogClassName="deltaFeedbackModal"
              animation={false}
            >
              <Modal.Header>
                <div className="homepage-book-modal_close">
                <CloseButton
                  style={{ float: "right", fontSize: "1.2rem", opacity: 0.8 }}
                  onClick={() => this.context.showFeedback()}
                />
                </div>
              </Modal.Header>
              <Modal.Body bsPrefix="document-data-modal">
              <Feedback
                onFeedbackFormClose={this.context.onFeedbackFormClose}
                closeFeedback={this.context.closeFeedback}
              />
              </Modal.Body>
            </Modal>
          )}

        <ToastContainer />
        {this.context.isLoading && isChannelPartner !== "1" && (
          <div className="text-center">
            <img className="loading-img" src={logo} alt="loading"></img>
          </div>
        )}
        {/* <Tips /> */}
        <ToastContainer />
        {this.context.isRefreshText && !this.context.toggleDocDepo && (
          <div>
            <button className="designbuttton">
              <div className="titlealign">Reset is complete</div>
              <div>
                <b>New Session is created.You can start your search again</b>
                <div>
                  <i className="fas fa-check-circle fa-lg text-success"></i>
                </div>
              </div>
            </button>
          </div>
        )}
        {/*  */}
        {/* <footer className="custom-footer"> */}
        {/* </footer> */}

        {this.state.showFooter && (
  <footer
  // Commented this code on 22nd Jan to avoid vertical scroll in delta
  // className={
  //   (!this.state.toggleDocDepo &&
  //     this.state.serviceData != null &&
  //     this.state.POCList != null) ||
  //   this.state.CustomerOpportunityData !== null
  //     ? "footer-after-results"
  //     : ""
  // }
>
            {
              <>
                {isChannelPartner === "1" &&
                window.location.pathname !== "/" ? (
                 null
                ) : (
      <>
        <div
          id="usefullinks"
          className="container-fluid mt-5 col-12 usefullinks "
        >
          {/* <span className="text_c" 
         onClick={()=> {
          this.setState({showFooter: this.state.showFooter == true ? false : true})
           }}> */}
                      <Button
                        variant="Secondary"
                        className="col-12 "
                        style={{
                          position: "relative",
                          zIndex: "1",
                          fontWeight: "900",
                        }}
                        onClick={() => {
                          this.setState({ showFooter: false });
                        }}
                      >
            A&PS Links
            </Button>
          <UseFulLinks />
          {/* <button style={{alignItems:"center"}}>A&PS Links</button> */}
        </div>
                  </>
                )}
        <div
          className="container-fluid text-center text-md-left"
          // style={{ paddingTop: "15px" }}
        >
          <div className="row">
            {/* <div className="col-md-4 mt-md-0 mt-3"></div> */}

                    <hr />

                    <div className="col-md-4 mb-md-0 mb-3 mt-2">
                      <p>
                        © Copyright {new Date().getFullYear()} Hewlett Packard
                        Enterprise Pvt. Ltd.
                      </p>
                    </div>

            <div
              className="col-md-4 mb-md-0 mb-3"
              align="right"
              style={{ marginTop: "13px" }}
            >
              Works best with Chrome.
            </div>
          </div>
        </div>
      </>
            }
    </footer>
    
)}
  
   {/* {this.state.showFooter && <UseFulLinks />} */}
        {
        isChannelPartner === "1" ? (
        <>
        {/* {!this.state.isBotLoaded &&  */}
          <div className="chatbot-container-channel-partner">
            {console.log("this.state.isBotLoaded 222")}
          <ChannelPartnerChatBot loadBot = {this.state.isBotLoaded}></ChannelPartnerChatBot>
           </div>
           </>
        ) : (
          <>

          </>
    )}

        {/* Modal for no custoemr details*/}
        <Modal
          size="md"
          show={this.context.noCustomerDetails}
          animation={false}
        >
          <ModalHeader>
            <h5 className="modal-title">Alert</h5>
          </ModalHeader>
          <Modal.Body>
            <p>
              {" "}
              Customer Details is not avaiable for the applied filter.<br></br>
              Click on <b>Yes</b> to Continue or click on <b>Reset</b> to reset
              the filter
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-sm btn-dark"
              onClick={() => {
                this.context.alertResetClicked();
                // this.setState({

                //   noCustomerDetails: false,
                //   doResetFilters: !this.state.doResetFilters,
                // });
              }}
            >
              Reset
            </Button>
            <Button
              className="btn btn-sm btn-success"
              onClick={() => {
                this.context.alertYesClicked();
                // this.setState({
                //   noCustomerDetails: false,
                //   CustomerOpportunityData: [],
                //   AccountID: "",
                // });
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* {this.context.confirmationPopup ? (
          <CustomDialogPopup
            confirmationPopup={this.context.confirmationPopup}
            message={this.context.message}
            title={this.context.title}
            buttons={this.context.buttons}
          />
        ) : null} */}
      </>
    );
  }
}
//export default withRouter(Home);
export default Home;
