import React, { useContext, useEffect, useState } from "react";
import HomePageImageMapper from "../Components/HomePageImageMapper";
import Cookies from "js-cookie";
import ChannelPartnersImageMapper from "../Components/ChannelPartnersImageMapper";
import URLConfig from "../URLConfig";
import axios from "axios";
import TrackingService from "../TrackingService";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import { toast } from "react-toastify";

const DeltaHomeStates = (props) => {
  const [currentHomeImageMapper, setCurrentHomeImageMapper] =
    useState(undefined);
  const [MasterData, setMasterData] = useState(null);
  const [showDocAnalysisModule, setshowDocAnalysisModule] = useState(false);
  const [PursuitDoc, setPursuitDoc] = useState(undefined);
  const [key, setkey] = useState(Math.random());
  const [toggleDocDepo, settoggleDocDepo] = useState(undefined);
  const [TrackingServices, setTrackingService] = useState(
    new TrackingService()
  );
  const [SelectedFilters, setSelectedFilters] = useState(null);
  const [filters, setfilters] = useState("&fq=-isarchived:True");
  const [isLoading, setisLoading] = useState(false);
  const [isRefreshText, setisRefreshText] = useState(false);
  const [ForFilterRefresh, setForFilterRefresh] = useState(Date.now());
  const [filterData, setfilterData] = useState([]);
  const [searchTerm, setsearchTerm] = useState("");
  const [SurveySearchItem, setSurveySearchItem] = useState("");
  const [lastSearchHistorySearch, setlastSearchHistorySearch] = useState(false);
  const [showHeader, setshowHeader] = useState(false);
  const [setselectedKnowledgeCategory, setsetselectedKnowledgeCategory] =
    useState([]);
  const [selectedReuseCategory, setselectedReuseCategory] = useState([]);
  const [maxScore, setmaxScore] = useState({
    guidesMaxScore: {
      item: "Guides",
      score: "",
      subitem: "",
    },
    presentationsMaxScore: {
      item: "Presentation",
      score: "",
      subitem: "",
    },
    servicesMaxScore: {
      item: "Services",
      score: "",
      subitem: "",
    },
    otherservicesMaxScore: {
      item: "Other Services",
      score: "",
      subitem: "",
    },
    multimediaMaxScore: {
      item: "Multimedia",
      score: "",
      subitem: "",
    },
    reuseMaxScore: {
      item: "Reuse",
      score: "",
      subitem: "",
    },
    trainingsMaxScore: {
      item: "Internal Trainings",
      score: "",
      subitem: "",
    },
    templatesMaxScore: {
      item: "Templates",
      score: "",
      subitem: "",
    },
    acceleratorMaxScore: {
      item: "Accelerator",
      score: "",
      subitem: "",
    },
  });
  const [checkSelectedCategory, setcheckSelectedCategory] = useState([]);
  const [acceleratorData, setacceleratorData] = useState(null);
  const [serviceData, setserviceData] = useState(null);
  const [SurveySearchResult, setSurveySearchResult] = useState(false);
  const [hpseData, sethpseData] = useState(null);
  const [SKUData, setSKUData] = useState(null);
  const [GuidesData, setGuidesData] = useState(null);
  const [MultiMediumData, setMultiMediumData] = useState(null);
  const [TrainingsData, setTrainingsData] = useState(null);
  const [PresentationsData, setPresentationsData] = useState(null);
  const [ServicesData, setServicesData] = useState(null);
  const [insightData, setinsightData] = useState(null);
  const [reuseDate, setreuseDate] = useState(null);
  const [TemplateSelected, setTemplateSelected] = useState([]);
  const [TemplatesData, setTemplatesData] = useState([]);
  const [TotalCount, setTotalCount] = useState(0);
  const [POCList, setPOCList] = useState(null);
  const [showOutageTemplate, setshowOutageTemplate] = useState(false);
  const [openSurvey, setopenSurvey] = useState(true);
  const [onSearchFormSubmitCompleted, setOnSearchFormSubmitCompleted] =
    useState(undefined);
  const [CustomerID, setCustomerID] = useState("");
  const [AccountID, setAccountID] = useState("");
  const [CustomerOpportunityData, setCustomerOpportunityData] = useState(null);
  const [account_name, setaccount_name] = useState(null);
  const [activeFilter, setactiveFilter] = useState("");
  const [fineSearchValue, setfineSearchValue] = useState("");
  const [onApplyFilterCompleted, setOnApplyFilterCompleted] =
    useState(undefined);
  const [onResetFiltersCompleted, setOnResetFiltersCompleted] =
    useState(undefined);
  const [FilterAppliedEarlier, setFilterAppliedEarlier] = useState(undefined);
  const [checkNDAstatus, setcheckNDAstatus] = useState(false);
  const [onClearFilterCompleted, setonClearFilterCompleted] =
    useState(undefined);
  const [clearFilterfiltersParam, setClearFilterfiltersParam] = useState(null);
  const [hardClearValue, sethardClearValue] = useState("");
  const [ProductName, setProductName] = useState(null);
  const [
    onCustomerSearchFormSubmitCompleted,
    setonCustomerSearchFormSubmitCompleted,
  ] = useState(undefined);
  const [rawAcid, setRawAcid] = useState(null);
  const [parentAccountStID, setparentAccountStID] = useState(null);
  const [getAccountDetailsCompleted, setgetAccountDetailsCompleted] =
    useState(undefined);
  const [
    ongetAccountIdByAccountNameCompleted,
    setongetAccountIdByAccountNameCompleted,
  ] = useState(undefined);
  const [
    getAccountIdByAccountNameAccountID,
    setGetAccountIdByAccountNameAccountID,
  ] = useState(null);
  const [noCustomerDetails, setnoCustomerDetails] = useState(undefined);
  const [doResetFilters, setdoResetFilters] = useState(undefined);
  const [onresetResultsCompleted, setOnresetResultsCompleted] =
    useState(undefined);
  const [showFeedbackform, setshowFeedbackform] = useState(false);
  const [clickedResetResults, setClickedResetResults] = useState(false);
  const [triggerRefineSearch, setTriggerRefineSearch] = useState(false);
  const [isDeltaUser, setisDeltaUser] = useState(true);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [buttons, setButtons] = useState({
    cancel: {
      label: "",
      callback: undefined,
    },
    confirm: {
      label: "",
      callback: undefined,
    },
  });
  const [archiveActive, setarchiveActive] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [fetchedServiceData, setFetchedServiceData] = useState(false);
  const [fetchedGuidesData, setFetchedGuidesData] = useState(false);
  const [fetchedTrainingsData, setFetchedTrainingsData] = useState(false);
  const [fetchedPresentationsData, setFetchedPresentationsData] =
    useState(false);
  const [fetchedServicesData, setFetchedServicesData] = useState(false);
  const [fetchedMultiMediumData, setFetchedMultiMediumData] = useState(false);
  const [fetchedSKUData, setFetchedSKUData] = useState(false);
  const [fetchedPOCList, setFetchedPOCList] = useState(false);
  const [fetchedAcceleratorData, setFetchedAcceleratorData] = useState(false);
  const [allDatafetched, setAllDatafetched] = useState(false);
  const [ChannelPartnerSearchTerm, setChannelPartnerSearchTerm] = useState("");
  const [onInitiateSearchCompleted, setOnInitiateSearchCompleted] = useState(false);

  const navigate = useNavigate();
  const ClearAFilter = React.createRef();
  const ClearSearch = React.createRef();
  const ClearArchivedDocs = React.createRef();
  const isChannelPartner = localStorage.getItem("isChannelPartner");

  useEffect(() => {
    console.log("URLConfig.getSolrBaseURL()", URLConfig.getSolrBaseURL());
  }, []);

  useEffect(() => {
    console.log(
      "loader useeffect",
      fetchedServiceData,
      fetchedGuidesData,
      fetchedTrainingsData,
      fetchedPresentationsData,
      fetchedServicesData,
      fetchedMultiMediumData,
      fetchedSKUData,
      fetchedPOCList,
      fetchedAcceleratorData
    );
    if (
      fetchedServiceData &&
      fetchedGuidesData &&
      fetchedTrainingsData &&
      fetchedPresentationsData &&
      fetchedServicesData &&
      fetchedMultiMediumData &&
      fetchedSKUData &&
      fetchedPOCList &&
      fetchedAcceleratorData
    ) {
      console.log("loader useeffect if");
      console.log("POCList useEffect 1", POCList);
      setShowLoader(false);
      setFetchedServiceData(false);
      setFetchedGuidesData(false);
      setFetchedTrainingsData(false);
      setFetchedPresentationsData(false);
      setFetchedServicesData(false);
      setFetchedMultiMediumData(false);
      setFetchedSKUData(false);
      setFetchedPOCList(false);
      setFetchedAcceleratorData(false);
    }
  }, [
    fetchedServiceData,
    fetchedGuidesData,
    fetchedTrainingsData,
    fetchedPresentationsData,
    fetchedServicesData,
    fetchedMultiMediumData,
    fetchedSKUData,
    fetchedPOCList,
    fetchedAcceleratorData,
  ]);

  // useEffect(()=>{
  //   console.log("POCList useEffect 1",POCList)

  // },[POCList])

  useEffect(() => {
    if (
      window.location.pathname.includes("/mindsparks") &&
      window.location.pathname.includes("/sow") &&
      window.location.pathname.includes("/intelligentresourcing") &&
      !window.location.pathname.includes("/channel-partner") &&
      window.location.pathname.includes("/sow-admin") &&
      searchTerm === "" &&
      !clickedResetResults
    ) {
      navigate("/");
    }
    if (window.location.pathname.includes("/search")&&
    searchTerm === "" &&
    !clickedResetResults){
      navigate("/");
    }
  }, [searchTerm]);

  const getAllMasterData = async () => {
    // try {
    //   const URL =
    //     URLConfig.getURLDeltaAPI() + "api/Document/GetAllMasterTablesData";
    //   const res = await axios.get(URL);

    //   setMasterData(res.data);
    // } catch (error) {
    //   console.log("error", error);
    // }
  };

  const showDocDepo = () => {
    setshowDocAnalysisModule(false);
    navigate("/document-depo");
    // toggleDocDepoFun()
    toggleDocDepoFun();
  };

  const showDocAnalysys = () => {
    setshowDocAnalysisModule(true);
    navigate("/document-analysis");
  };

  const toggleDocDepoFun = () => {
    // this.MoveSearchElements();
    if (document.getElementById("SearchAutoSuggestionsPrimary") !== null) {
      document.getElementById("SearchAutoSuggestionsPrimary").hidden = true;
      document.getElementById("content").hidden = true;
    }
    TrackingServices.LogDocDepoClick(Cookies.get("empnumber"), true);
    settoggleDocDepo(true);
    navigate("/document-depo");
    // window.open("/document-depo", "_blank")

    // Below line is commented as it's not required in the new home page design
    // date-03/01/2024
    // console.log(Cookies.get("empnumber"), "DocDepo");
    // this.setState((prevState) => ({ toggleDocDepo: !prevState.toggleDocDepo }));
  };

  const processPursuitDocument = (PursuitDoc) => {
    //alert(DocumentName);
    const ISInDocDepo = toggleDocDepo;
    if (!ISInDocDepo) {
      setPursuitDoc(PursuitDoc);
    } else {
      document.getElementsByClassName("Doc-Depo-Heading")[0].click();
    }
  };

  const refreshDocDepo = () => {
    setkey(Math.random());
  };

 
  useEffect(() => {
    
    if (isChannelPartner === "1") {
        setCurrentHomeImageMapper(<ChannelPartnersImageMapper />);
    } else {
        setCurrentHomeImageMapper(<HomePageImageMapper />);
    }
  }, []);

  useEffect(() => {
    const result = async () => {
      await getAllMasterData();
    };
    result();
  }, []);

  const homeredirect = () => {

    if(window.location.pathname.includes("/document-depo")){
      navigate("/");
    }
    else if(window.location.pathname.includes("/document-analysis")){
         navigate("/");
    }
    window.location.href = window.location.href;
  };

  const onSearchFormSubmit = (
    searchTermParam,
    filtersParam = filters,
    SelectedFiltersParam = SelectedFilters,
    lastSearchHistorySearch
  ) => {
    if (searchTermParam !== searchTerm) {
      setisLoading(true);
      setisRefreshText(false);
      setsearchTerm(searchTermParam);
      setForFilterRefresh(Date.now());
      setfilterData([]);
      setSelectedFilters(SelectedFiltersParam);
      setfilters(filtersParam);
      setSurveySearchItem(searchTermParam);
      setlastSearchHistorySearch(lastSearchHistorySearch);
      setshowHeader(true);
      settoggleDocDepo(false);
      setshowDocAnalysisModule(false);
      setOnSearchFormSubmitCompleted(true);

      // this.setState(
      //   {
      //     isLoading: true,
      //     isRefreshText: false,
      //     searchTerm,
      //     ForFilterRefresh: Date.now(),
      //     filterData: [],
      //     SelectedFilters: SelectedFilters,
      //     filters: filters,
      //     SurveySearchItem: searchTerm,
      //     lastSearchHistorySearch: lastSearchHistorySearch,
      //     showHeader: true,
      //   },
      //   () => {
      //     this.CheckEneteredLanguage(searchTerm);
      //   }
      // );
    } else if (triggerRefineSearch) {
      return;
    } else {
      setisLoading(true);
      setisRefreshText(false);
      setsearchTerm(searchTermParam);
      setfilterData([]);

      setOnSearchFormSubmitCompleted(true);
      // this.setState(
      //   { isLoading: true, isRefreshText: false, searchTerm, filterData: [] },
      //   () => {
      //     this.CheckEneteredLanguage(searchTerm);
      //   }
      // );
    }
    // setOnSearchFormSubmitCompleted(true);
  };
  useEffect(() => {
    if (
      onSearchFormSubmitCompleted === undefined ||
      !onSearchFormSubmitCompleted ||
      searchTerm === ""
    ) {
      return;
    }
    CheckEneteredLanguage(searchTerm);
  }, [
    onSearchFormSubmitCompleted,
    searchTerm,
    SelectedFilters,
    filters,
    ForFilterRefresh,
  ]);

  const CheckEneteredLanguage = (searchTermParam) => {
    const UserLang = Cookies.get("UserLang");
    if (UserLang === undefined) {
      InitiateSearch(searchTermParam);
      setChannelPartnerSearchTerm(searchTermParam)
    } else if (UserLang === "en") {
      InitiateSearch(searchTermParam);
      setChannelPartnerSearchTerm(searchTermParam)
    } else {
      const config = URLConfig.ApplyAuth(
        "https://hpedelta.com:5003/services/translator/query?q=" +
          searchTermParam +
          "&to_lang=en"
      );
      axios(config).then((res) => {
        if (res.data.response) {
          let modifiedSearchTerm = res.data.response;
          if (!modifiedSearchTerm.includes("unique_file")) {
            modifiedSearchTerm = modifiedSearchTerm.replace(
              /[!#$%&()*+,-./;<=>?@[\]^`{|}~]+/g,
              " "
            );
            modifiedSearchTerm = modifiedSearchTerm.replace(
              /(?<!unique)_(?!file)/g,
              " "
            );
          }

          setChannelPartnerSearchTerm(modifiedSearchTerm);
        }
      });
    }
    // document.getElementById("SearchAutoSuggestionsPrimary").hidden = true;
  };
  useEffect(() => {
    console.log("InitiateSearch InitiateSearch", ChannelPartnerSearchTerm);
    if (ChannelPartnerSearchTerm !== "") {
      InitiateSearch(ChannelPartnerSearchTerm);
    }
  }, [ChannelPartnerSearchTerm]);

  const InitiateSearch = (searchTerm) => {
    console.log("searchTermParam ", searchTerm, searchTerm);
    setOnInitiateSearchCompleted(false)
    try {
      // document.getElementById("SearchAutoSuggestionsPrimary").hidden = true;
      // document.getElementById("content").hidden = true;
      // document.getElementById("searchBtn").hidden = true;
      // document.getElementById("practiceProjectDic").hidden = true;
      setShowLoader(true);
      var CustomerCapsuleLoading = false;
      let checkCategory = [];
      let selectedKnowledgeCategory = setselectedKnowledgeCategory;
      let selectedReuseCategoryParam = selectedReuseCategory;
      TrackingServices.LogSearch(Cookies.get("empnumber"), searchTerm);
      // Below line is commented as it's not required in the new home page design
      // date-03/01/2024
      // !this.state.isMovedAlready && this.MoveSearchElements();

      // searchTerm = encodeURIComponent(searchTerm);
      // searchTerm = searchTerm.replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+/g,"*");

      /*Added this code as the previous code was replacing _ in unique file with * resulting not getting proper response from API */
      if (!searchTerm.includes("unique_file")) {
        searchTerm = searchTerm.replace(
          /[!#$%&()*+,-./;<=>?@[\]^`{|}~]+/g,
          " "
        );
        searchTerm = searchTerm.replace(/(?<!unique)_(?!file)/g, " ");
        // Commented on 31st Jan 2024 as for the file containing _ and - it was not giving proper response
        // searchTerm = encodeURIComponent(searchTerm);
      }
      const URL =
        URLConfig.getURLKnowledgeCapsule(encodeURIComponent(searchTerm)) +
        filters +
        (filters.indexOf('&fq=nda:"True"') === -1
          ? // "&fq=-nda:%22True%22"
            URLConfig.isNDA(true)
          : "");
      // "https://hpedelta.com:8983/solr/si_template/select?defType=edismax&fl=id,title,isgoldcollateral,file,url,disclosure_level,rating,file_type,doc_source,language_s,competitors_covered,research_vendors,industry_segment,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,document_type,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type&group=true&indent=on&q=Accelerator&rows=500&wt=json&group.limit=150&fq=scope:%22Service%20Capsule%22%20AND%20-document_type:%22Services%22%20AND%20-document_type:%22Guides%22%20AND%20-document_type:%22Presentations%22%20AND%20-document_type:%22POC%22%20AND%20-document_type:%22Multimedia%22%20AND%20-document_type:%22Internal%20Trainings%22&fq=-file_type:%22msg%22&fq=persona_role:*Delivery*%20OR%20persona_role:*Practice*%20OR%20persona_role:*Pursuit*%20OR%20persona_role:*Sales*%20OR%20persona_role:*Admin*&fq=-isarchived:True&fq=-nda:%22True%22"
      // axios
      //   .get(URL)
      URLConfig.getnewsolrBaseURL(URL)
        .then((res) => {
          setFetchedAcceleratorData(true);
          const serviceDataParam = res.data.grouped.document_type.groups;
          var knowledgeCategory = [];

          if (isChannelPartner === "1") {
            knowledgeCategory = serviceDataParam;
          } else {
            knowledgeCategory = serviceDataParam.filter((item) => {
              return selectedKnowledgeCategory.find((item_) => {
                return item.groupValue === item_.categoryName;
              });
            });
          }
          if (
            serviceDataParam.filter((item) => item.groupValue == "Accelerators")
              .length > 0
          ) {
            const acceleratorDocList = serviceDataParam.find(
              (item) => item.groupValue == "Accelerators"
            );

            checkCategory = [...checkCategory, "Accelerators"];
            setcheckSelectedCategory(checkCategory);
            setacceleratorData(acceleratorDocList.doclist);
            setmaxScore((prevMaxScore) => {
              return {
                ...prevMaxScore,
                acceleratorMaxScore: {
                  score: acceleratorDocList.doclist.maxScore
                    ? acceleratorDocList.doclist.maxScore
                    : "",
                  item: "Accelerator",
                  subitem: acceleratorDocList.groupValue
                    ? acceleratorDocList.groupValue
                    : "",
                },
              };
            });

            //   this.setState((prevState) => ({
            //     checkSelectedCategory: checkCategory,
            //     acceleratorData: acceleratorDocList.doclist,
            //     maxScore: {
            //       ...prevState.maxScore,
            //       acceleratorMaxScore: {
            //         score: acceleratorDocList.doclist.maxScore
            //           ? acceleratorDocList.doclist.maxScore
            //           : "",
            //         item: "Accelerator",
            //         subitem: acceleratorDocList.groupValue
            //           ? acceleratorDocList.groupValue
            //           : "",
            //       },
            //     },
            //   }));
            // }
          }
          if (res.data.grouped.document_type.matches > 0) {
            checkCategory = [...checkCategory, "Other Services"];
            // this.setState({ checkSelectedCategory: checkCategory });
            setcheckSelectedCategory(checkCategory);
          }
          processGroupFilters(knowledgeCategory);
          if (!CustomerCapsuleLoading) {
            setserviceData(knowledgeCategory);
            setFetchedServiceData(true);
            setisLoading(false);
            // this.setState({
            //   serviceData: knowledgeCategory,
            //   isLoading: false,
            // });
            if (knowledgeCategory.length > 0) {
              setTimeout(() => {
                setSurveySearchResult(true);
                // this.setState({ SurveySearchResult: true });
              }, 2000);

              getopenSurvey();
            }
          } else {
            setserviceData(knowledgeCategory);
            setFetchedServiceData(true);
            // this.setState({ serviceData: knowledgeCategory });
          }
          if (res.data.grouped.document_type.groups.length > 0) {
            setmaxScore((prevMaxScore) => {
              return {
                ...prevMaxScore,
                otherservicesMaxScore: {
                  score: res.data.grouped.document_type.groups[0].doclist
                    .maxScore
                    ? res.data.grouped.document_type.groups[0].doclist.maxScore
                    : "",
                  item: "Other Services",
                  subitem: res.data.grouped.document_type.groups[0].groupValue
                    ? res.data.grouped.document_type.groups[0].groupValue
                    : "",
                },
              };
            });
            // this.setState((prevState) => ({
            //   maxScore: {
            //     ...prevState.maxScore,
            //     otherservicesMaxScore: {
            //       score: res.data.grouped.document_type.groups[0].doclist
            //         .maxScore
            //         ? res.data.grouped.document_type.groups[0].doclist.maxScore
            //         : "",
            //       item: "Other Services",
            //       subitem: res.data.grouped.document_type.groups[0].groupValue
            //         ? res.data.grouped.document_type.groups[0].groupValue
            //         : "",
            //     },
            //   },
            // }));
          }
        })
        .catch((err) => {});

      // Commented on 2nd April as we are not displaying HPE support center in DELTA anymore
      // axios.get(URLConfig.getURL_HPSE(searchTerm) + filters).then((res) => {
      //   if (res.data) {
      //     const hpseData = res.data.results;
      //     sethpseData(hpseData);
      //     // this.setState({ hpseData });
      //   }
      // });
      if (isChannelPartner === "0") {
        axios.get(URLConfig.getURLSKU(searchTerm) + filters).then((res) => {
          if (res.data.response) {
            const SKUData = res.data.response.docs;
            setSKUData(SKUData);
            setFetchedSKUData(true);
            // this.setState({ SKUData });
          }
        });
      } else {
        setFetchedSKUData(true);
      }

      const URLGuides =
        URLConfig.get_APIHost() +
        // "solr/sharepoint_index/select?defType=edismax&fl=id,title,isgoldcollateral,file,url,disclosure_level,rating,file_type,doc_source,language_s,competitors_covered,research_vendors,industry_vertical,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type_details&group=true&indent=on&q=" +
        URLConfig.getSolrBaseURL() +
        "/select?defType=edismax&fl=id,title,isgoldcollateral,file,url," +
        URLConfig.isDisclosureLevel() +
        "rating,file_type,doc_source,language_s,competitors_covered,research_vendors,industry_vertical,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type_details&group=true&indent=on&q=" +
        searchTerm +
        // "&rows=500&wt=json&group.limit=15&fq=scope:%22Service%20Capsule%22&fq=document_type:%22Guides%22" +
        "&rows=500&wt=json&group.limit=15&fq=scope:%22Service%20Capsule%22&fq=document_type:%22Guides%22&fq=-document_type_details:%22bios%22&fq=-document_type_details:%22Article%22&fq=-document_type_details:%22services data sheet%22&fq=-document_type_details:%22sales briefcase|seismic page%22" +
        (filters.indexOf('&fq=nda:"True"') === -1
          ? // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
            URLConfig.isNDA(true) + URLConfig.GetUserRoles()
          : "") +
        filters;
        // const payload = URLConfig.encryptMessage(URLGuides)

      // axios.get(URLGuides).
      URLConfig.getnewsolrBaseURL(URLGuides)
      .then(async (res) => {
        console.log("GuidesData", res);
        if (res.data.grouped) {
          console.log("GuidesData if initiate");

          setGuidesData(res.data.grouped.document_type_details);

          setFetchedGuidesData(true);

          // this.setState({ GuidesData: res.data.grouped.document_type_details });
          if (res.data.grouped.document_type_details.matches > 0) {
            checkCategory = [...checkCategory, "Guides"];
            setcheckSelectedCategory(checkCategory);
            // this.setState({ checkSelectedCategory: checkCategory });
          }
          processGroupFilters(res.data.grouped.document_type_details.groups);
        }
        if (res.data.grouped.document_type_details.groups.length > 0) {
          setmaxScore((prevMaxScore) => {
            return {
              ...prevMaxScore,
              guidesMaxScore: {
                score:
                  res.data.grouped.document_type_details.groups[0].doclist
                    .maxScore,
                item: "Guides",
                subitem:
                  res.data.grouped.document_type_details.groups[0].groupValue,
              },
            };
          });
          // this.setState((prevState) => ({
          //   maxScore: {
          //     ...prevState.maxScore,
          //     guidesMaxScore: {
          //       score:
          //         res.data.grouped.document_type_details.groups[0].doclist
          //           .maxScore,
          //       item: "Guides",
          //       subitem:
          //         res.data.grouped.document_type_details.groups[0].groupValue,
          //     },
          //   },
          // }));
        }
      });
      //Multimedia with Sub categories

      //Changed the URL to maintain 3 category under multimedia audio video and infographics

      // const URLMultiMedium =
      // URLConfig.get_APIHost() +
      // "solr/sharepoint_index/select?defType=edismax&fl=id,title,isgoldcollateral,file,url,disclosure_level,rating,file_type,doc_source,language_s,competitors_covered,research_vendors,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type_details&group=true&indent=on&q=" +
      // searchTerm +
      // "&rows=500&wt=json&group.limit=15&fq=scope:%22Service%20Capsule%22&fq=document_type:%22Multimedia%22" +
      // (this.state.filters.indexOf('&fq=nda:"True"') === -1
      //   ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
      //   : "") +
      // this.state.filters;

      const URLMultiMedium =
        URLConfig.get_APIHost() +
        // "solr/sharepoint_index/select?defType=edismax&fl=document_type_details,%20id,title,isgoldcollateral,file,url,disclosure_level,rating,file_type,doc_source,language_s,competitors_covered,research_vendors,industry_vertical,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type_details&group=true&indent=on&q=" +
        URLConfig.getSolrBaseURL() +
        "/select?defType=edismax&fl=document_type_details,%20id,title,isgoldcollateral,file,url," +
        URLConfig.isDisclosureLevel() +
        "rating,file_type,doc_source,language_s,competitors_covered,research_vendors,industry_vertical,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type_details&group=true&indent=on&q=" +
        searchTerm +
        "&rows=500&wt=json&group.limit=15&fq=scope:%22Service%20Capsule%22&fq=document_type_details:*Infographic*%20OR%20document_type_details:*Video*%20OR%20document_type_details:*Audio*" +
        (filters.indexOf('&fq=nda:"True"') === -1
          ? // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
            URLConfig.isNDA(true) + URLConfig.GetUserRoles()
          : "") +
        filters;
        // const URLMultiMediumPayload = URLConfig.encryptMessage(URLMultiMedium)

      // axios.get(URLMultiMedium)
      URLConfig.getnewsolrBaseURL(URLMultiMedium)
      .then((res) => {
        if (res.data.grouped) {
          setMultiMediumData(res.data.grouped.document_type_details);
          setFetchedMultiMediumData(true);
          // this.setState({
          //   MultiMediumData: res.data.grouped.document_type_details,
          // });
          if (res.data.grouped.document_type_details.matches > 0) {
            checkCategory = [...checkCategory, "Multimedia"];
            setcheckSelectedCategory(checkCategory);
            // this.setState({ checkSelectedCategory: checkCategory });
          }
          processGroupFilters(res.data.grouped.document_type_details.groups);
        }
        if (res.data.grouped.document_type_details.groups.length > 0) {
          setmaxScore((prevMaxScore) => {
            return {
              ...prevMaxScore,
              multimediaMaxScore: {
                score:
                  res.data.grouped.document_type_details.groups[0].doclist
                    .maxScore,
                item: "MultiMedia",
                subitem:
                  res.data.grouped.document_type_details.groups[0].groupValue,
              },
            };
          });
          // this.setState((prevState) => ({
          //   maxScore: {
          //     ...prevState.maxScore,
          //     multimediaMaxScore: {
          //       score:
          //         res.data.grouped.document_type_details.groups[0].doclist
          //           .maxScore,
          //       item: "MultiMedia",
          //       subitem:
          //         res.data.grouped.document_type_details.groups[0].groupValue,
          //     },
          //   },
          // }));
        }
      });

      // Internal Trainings
      const URLTrainings =
        URLConfig.get_APIHost() +
        // "solr/sharepoint_index/select?defType=edismax&fl=id,title,isgoldcollateral,file,url,disclosure_level,rating,file_type,doc_source,language_s,competitors_covered,research_vendors,industry_vertical,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type_details&group=true&indent=on&q=" +
        URLConfig.getSolrBaseURL() +
        "/select?defType=edismax&fl=id,title,isgoldcollateral,file,url," +
        URLConfig.isDisclosureLevel() +
        "rating,file_type,doc_source,language_s,competitors_covered,research_vendors,industry_vertical,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type_details&group=true&indent=on&q=" +
        searchTerm +
        '&rows=500&wt=json&group.limit=15&fq=scope:%22Service%20Capsule%22&fq=document_type:%22Internal%20Trainings%22&fq=document_type_details:("Saba", "Other Trainings")' +
        (filters.indexOf('&fq=nda:"True"') === -1
          ? // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
            URLConfig.isNDA(true) + URLConfig.GetUserRoles()
          : "") +
        URLConfig.GetUserRoles() +
        filters;
        // const URLTrainingsPayload = URLConfig.encryptMessage(URLTrainings)

      // axios.get(URLTrainings)
      URLConfig.getnewsolrBaseURL(URLTrainings)
      .then((res) => {
        if (res.data.grouped) {
          setTrainingsData(res.data.grouped.document_type_details);
          setFetchedTrainingsData(true);
          // this.setState({
          //   TrainingsData: res.data.grouped.document_type_details,
          // });
          if (res.data.grouped.document_type_details.matches > 0) {
            checkCategory = [...checkCategory, "Training"];
            setcheckSelectedCategory(checkCategory);
            // this.setState({ checkSelectedCategory: checkCategory });
          }
          processGroupFilters(res.data.grouped.document_type_details.groups);
        }
        if (res.data.grouped.document_type_details.groups.length > 0) {
          setmaxScore((prevMaxScore) => {
            return {
              ...prevMaxScore,
              trainingsMaxScore: {
                score:
                  res.data.grouped.document_type_details.groups[0].doclist
                    .maxScore,
                item: "Internal Trainings",
                subitem:
                  res.data.grouped.document_type_details.groups[0].groupValue,
              },
            };
          });
          // this.setState((prevState) => ({
          //   maxScore: {
          //     ...prevState.maxScore,
          //     trainingsMaxScore: {
          //       score:
          //         res.data.grouped.document_type_details.groups[0].doclist
          //           .maxScore,
          //       item: "Internal Trainings",
          //       subitem:
          //         res.data.grouped.document_type_details.groups[0].groupValue,
          //     },
          //   },
          // }));
        }
      });

      const URLPresentations =
        URLConfig.get_APIHost() +
        // "solr/sharepoint_index/select?defType=edismax&fl=id,title,isgoldcollateral,file,url,disclosure_level,rating,file_type,doc_source,language_s,competitors_covered,research_vendors,industry_vertical,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type_details&group=true&indent=on&q=" +
        URLConfig.getSolrBaseURL() +
        "/select?defType=edismax&fl=id,title,isgoldcollateral,file,url," +
        URLConfig.isDisclosureLevel() +
        "rating,file_type,doc_source,language_s,competitors_covered,research_vendors,industry_vertical,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type_details&group=true&indent=on&q=" +
        searchTerm +
        "&rows=500&wt=json&group.limit=15&fq=scope:%22Service%20Capsule%22&fq=document_type:%22Presentations%22" +
        (filters.indexOf('&fq=nda:"True"') === -1
          ? // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
            URLConfig.isNDA(true) + URLConfig.GetUserRoles()
          : "") +
        filters;
        // const URLPresentationsPayload = URLConfig.encryptMessage(URLPresentations)

      // axios.get(URLPresentations)
      URLConfig.getnewsolrBaseURL(URLPresentations)
      .then((res) => {
        if (res.data.grouped) {
          setPresentationsData(res.data.grouped.document_type_details);
          setFetchedPresentationsData(true);
          // this.setState({
          //   PresentationsData: res.data.grouped.document_type_details,
          // });
          if (res.data.grouped.document_type_details.matches > 0) {
            checkCategory = [...checkCategory, "Presentations"];
            setcheckSelectedCategory(checkCategory);
            // this.setState({ checkSelectedCategory: checkCategory });
          }
          processGroupFilters(res.data.grouped.document_type_details.groups);
        }
        if (res.data.grouped.document_type_details.groups.length > 0) {
          setPresentationsData(res.data.grouped.document_type_details);
          setFetchedPresentationsData(true);
          setmaxScore((prevMaxScore) => {
            return {
              ...prevMaxScore,
              presentationsMaxScore: {
                score:
                  res.data.grouped.document_type_details.groups[0].doclist
                    .maxScore,
                item: "Presentation",
                subitem:
                  res.data.grouped.document_type_details.groups[0].groupValue,
              },
            };
          });
          // this.setState((prevState) => ({
          //   PresentationsData: res.data.grouped.document_type_details,
          //   maxScore: {
          //     ...prevState.maxScore,
          //     presentationsMaxScore: {
          //       score:
          //         res.data.grouped.document_type_details.groups[0].doclist
          //           .maxScore,
          //       item: "Presentation",
          //       subitem:
          //         res.data.grouped.document_type_details.groups[0].groupValue,
          //     },
          //   },
          // }));
        }
      });

      const URLServices =
        URLConfig.get_APIHost() +
        // "solr/sharepoint_index/select?defType=edismax&fl=id,title,isgoldcollateral,file,url,disclosure_level,rating,file_type,doc_source,language_s,competitors_covered,research_vendors,system_integrators,industry_vertical,technology_partners,product_line,business_segment,region,country,creation_date,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,description,go_to_market,service_name,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type_details&group=true&indent=on&q=" +
        URLConfig.getSolrBaseURL() +
        "/select?defType=edismax&fl=id,title,isgoldcollateral,file,url," +
        URLConfig.isDisclosureLevel() +
        "rating,file_type,doc_source,language_s,competitors_covered,research_vendors,system_integrators,industry_vertical,technology_partners,product_line,business_segment,region,country,creation_date,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,description,go_to_market,service_name,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type_details&group=true&indent=on&q=" +
        searchTerm +
        "&rows=500&wt=json&group.limit=15&fq=scope:%22Service%20Capsule%22&fq=document_type:%22Services%22" +
        (filters.indexOf('&fq=nda:"True"') === -1
          ? // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
            URLConfig.isNDA(true) + URLConfig.GetUserRoles()
          : "") +
        filters;
      // axios.get(URLServices)
      URLConfig.getnewsolrBaseURL(URLServices)
      .then((res) => {
        console.log("res res res", res);
        if (res.data.grouped) {
          console.log("if initiate search");
          setServicesData(res.data.grouped.document_type_details);
          setFetchedServicesData(true);

          // this.setState({
          //   ServicesData: res.data.grouped.document_type_details,
          // });
          if (res.data.grouped.document_type_details.matches > 0) {
            checkCategory = [...checkCategory, "Service"];
            setcheckSelectedCategory(checkCategory);
            // this.setState({ checkSelectedCategory: checkCategory });
          }
          processGroupFilters(res.data.grouped.document_type_details.groups);
        }
        if (res.data.grouped.document_type_details.groups.length > 0) {
          setmaxScore((prevMaxScore) => {
            return {
              ...prevMaxScore,
              servicesMaxScore: {
                score:
                  res.data.grouped.document_type_details.groups[0].doclist
                    .maxScore,
                item: "Services",
                subitem:
                  res.data.grouped.document_type_details.groups[0].groupValue,
              },
            };
          });
          // this.setState((prevState) => ({
          //   maxScore: {
          //     ...prevState.maxScore,
          //     servicesMaxScore: {
          //       score:
          //         res.data.grouped.document_type_details.groups[0].doclist
          //           .maxScore,
          //       item: "Services",
          //       subitem:
          //         res.data.grouped.document_type_details.groups[0].groupValue,
          //     },
          //   },
          // }));
        }
      });

      // const URLMultimedia =
      //   URLConfig.get_APIHost() +
      //   "solr/sharepoint_index/select?defType=edismax&fl=id,title,isgoldcollateral,file,url,disclosure_level,rating,file_type,doc_source,language_s,competitors_covered,research_vendors,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,nda,ndamails_raw,score,isarchived&group.field=document_type_details&group=true&indent=on&q=" +
      //   searchTerm +
      //   '&rows=500&wt=json&group.limit=15&fq=scope:%22Service%20Capsule%22&fq=document_type:%22Multimedia%22' +
      //   (this.state.filters.indexOf("&fq=nda:\"True\"") === -1 ? "&fq=-nda:%22True%22" : "") +
      //   URLConfig.GetUserRoles() +
      //   this.state.filters;
      // axios.get(URLMultimedia).then((res) => {
      //   if (res.data.grouped) {
      //     this.setState((prevState) => ({
      //       MultimediaData: res.data.grouped.document_type_details,
      //       maxScore: {
      //         ...prevState.maxScore,
      //         multimediaMaxScore: {
      //           score: res.data.grouped.document_type_details.groups[0].doclist
      //             .maxScore
      //             ? res.data.grouped.document_type_details.groups[0].doclist
      //                 .maxScore
      //             : "",
      //           item: "Multimedia",
      //         },
      //       },
      //     }));
      //     this.processGroupFilters(res.data.grouped.document_type_details.groups);
      //   }
      // });

      // NK
      // ReUseInsight

      if (!searchTerm.includes("unique_file")) {
        let cleanSearchTerm = searchTerm;
        const index = cleanSearchTerm.indexOf("+");
        if (index > 0) cleanSearchTerm = cleanSearchTerm.substring(0, index);
        if (isChannelPartner === "0") {
          axios
            .get(
              URLConfig.getURLReUseInsight(cleanSearchTerm) +
                filters.replace("&fq=-isarchived:True", "")
            )
            .then(({ data }) => {
              if (data.grouped && data.grouped.Opportunity_Sales_Stage) {
                setinsightData(data.grouped.Opportunity_Sales_Stage);

                // this.setState({
                //   insightData: data.grouped.Opportunity_Sales_Stage,
                // });
              } else {
                setinsightData(null);
                // this.setState({ insightData: null });
              }
            });
        }
      } else {
        setinsightData(null);
        // this.setState({ insightData: null });
      }
      const URLReuse = URLConfig.getURLReUseCapsule(searchTerm) +
      (filters.indexOf('&fq=nda:"True"') === -1
        ? // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
          URLConfig.isNDA(true) + URLConfig.GetUserRoles()
        : "") +
      filters

      // axios
      //   .get(
      //     URLConfig.getURLReUseCapsule(searchTerm) +
      //       (filters.indexOf('&fq=nda:"True"') === -1
      //         ? // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
      //           URLConfig.isNDA(true) + URLConfig.GetUserRoles()
      //         : "") +
      //       filters
      //   )
      URLConfig.getnewsolrBaseURL(URLReuse)
        .then((res) => {
          if (res.data.grouped && res.data.grouped.document_type.groups) {
            const reuseDate = res.data.grouped.document_type.groups;
            if (res.data.grouped.document_type.matches > 0) {
              checkCategory = [...checkCategory, "Reuse"];
              setcheckSelectedCategory(checkCategory);
              // this.setState({ checkSelectedCategory: checkCategory });
            }
            var reuseCategory = reuseDate.filter((item) => {
              return selectedReuseCategoryParam.find((item_) => {
                return item.groupValue === item_.categoryName;
              });
            });
            setreuseDate(reuseCategory);
            // this.setState({ reuseDate: reuseCategory });
            if (res.data.grouped.document_type.groups.length > 0) {
              setmaxScore((prevMaxScore) => {
                return {
                  ...prevMaxScore,
                  reuseMaxScore: {
                    score:
                      res.data.grouped.document_type.groups[0].doclist.maxScore,
                    item: "Reuse",
                    subitem:
                      res.data.grouped.document_type.groups[0].groupValue,
                  },
                };
              });
              // this.setState((prevState) => ({
              //   maxScore: {
              //     ...prevState.maxScore,
              //     reuseMaxScore: {
              //       score:
              //         res.data.grouped.document_type.groups[0].doclist.maxScore,
              //       item: "Reuse",
              //       subitem:
              //         res.data.grouped.document_type.groups[0].groupValue,
              //     },
              //   },
              // }));
            }
            processGroupFilters(reuseCategory);
          }
        });
      // For Templates Category Under Reference Capsule
      if (isChannelPartner === "0") {
        const templatesURL =
          "https://hpedelta.com:8983/solr/si_template/select?facet.pivot=document_type,document_type_details,document_type_level3,document_type_level4&facet=on&fl=file,score,document_type,document_type_details,document_type_level3,document_type_level4&fq=document_type:%22Templates%22%20AND%20scope:%22Reuse%22&indent=on&q=" +
          searchTerm +
          "&rows=1&wt=json" +
          (filters.indexOf('&fq=nda:"True"') === -1
            ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
            : "") +
          filters;
        axios.get(templatesURL).then((res) => {
          if (res.data.response.numFound > 0) {
            setTemplateSelected(res.data.response);
            setTemplatesData(
              res?.data?.facet_counts?.facet_pivot[
                "document_type,document_type_details,document_type_level3,document_type_level4"
              ][0]?.pivot
            );
            setTotalCount(res?.data?.response?.numFound);

            // this.setState({
            //   TemplateSelected: res.data.response,
            //   TemplatesData:
            //     res?.data?.facet_counts?.facet_pivot[
            //       "document_type,document_type_details,document_type_level3,document_type_level4"
            //     ][0]?.pivot,
            //   TotalCount: res?.data?.response?.numFound,
            // });
            setmaxScore((prevMaxScore) => {
              return {
                ...prevMaxScore,
                templatesMaxScore: {
                  score: res.data.response.maxScore,
                  item: "Templates",
                  subitem: res.data.response.docs[0].document_type_details,
                },
              };
            });

            // this.setState((prevState) => ({
            //   maxScore: {
            //     ...prevState.maxScore,
            //     templatesMaxScore: {
            //       score: res.data.response.maxScore,
            //       item: "Templates",
            //       subitem: res.data.response.docs[0].document_type_details,
            //     },
            //   },
            // }));
          } else {
            setTemplatesData([]);
            setTotalCount(res?.data?.response?.numFound);
            // this.setState({
            //   TemplatesData: [],
            //   TotalCount: res?.data?.response?.numFound,
            // });
          }
        });
      }

      //This is for EOC under Service section for only fill filters data..
      const URLEOC =
        URLConfig.get_APIHost() +
        // "solr/sharepoint_index/select?fl=id,title,isgoldcollateral,file,url,disclosure_level,rating,file_type,doc_source,language_s,competitors_covered,research_vendors,industry_vertical,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,isarchived,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,document_type_level3,nda,ndamails_raw,score&fq=scope:%22Service%20Capsule%22%20AND%20document_type_details:%22Education%20and%20MoC%22%20AND%20document_type:%22Services%22&group.field=document_type_level3&group.limit=10&group.mincount=1&group=true&indent=on&q=" +
        URLConfig.getSolrBaseURL() +
        "/select?fl=id,title,isgoldcollateral,file,url," +
        URLConfig.isDisclosureLevel() +
        "rating,file_type,doc_source,language_s,competitors_covered,research_vendors,industry_vertical,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,isarchived,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,document_type_level3,nda,ndamails_raw,score&fq=scope:%22Service%20Capsule%22%20AND%20document_type_details:%22Education%20and%20MoC%22%20AND%20document_type:%22Services%22&group.field=document_type_level3&group.limit=10&group.mincount=1&group=true&indent=on&q=" +
        searchTerm +
        "&rows=500&wt=json" +
        (filters.indexOf('&fq=nda:"True"') === -1
          ? // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
            URLConfig.isNDA(true) + URLConfig.GetUserRoles()
          : "") +
        filters;
      // axios.get(URLEOC)
      URLConfig.getnewsolrBaseURL(URLEOC)
      .then((res) => {
        if (res.data.grouped && res.data.grouped.document_type_level3) {
          processGroupFilters(res.data.grouped.document_type_level3.groups);
        }
      });
      const url =
        URLConfig.get_APIHost() +
        // "solr/sharepoint_index/select?fq=document_type:%22POC%22&indent=on&q=" +
        URLConfig.getSolrBaseURL() +
        "/select?fq=document_type:%22POC%22&indent=on&q=" +
        searchTerm +
        "&wt=json&rows=500" +
        (filters.indexOf('&fq=nda:"True"') === -1
          ? // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
            URLConfig.isNDA(true) + URLConfig.GetUserRoles()
          : "") +
        filters;
      // axios.get(url)
      URLConfig.getnewsolrBaseURL(url)
      .then((res) => {
        if (res.data.response && res.data.response.docs) {
          setPOCList(res.data.response.docs);
          setFetchedPOCList(true);

          // this.setState({
          //   POCList: res.data.response.docs,
          // });
          processFilters(res.data.response.docs);
        } else {
          setPOCList([]);
          setFetchedPOCList(true);
          // this.setState({
          //   POCList: [],
          // });
        }
      });
    } catch (error) {
      console.log(error);
      setshowOutageTemplate(true);
      setisLoading(false);
      // this.setState({ showOutageTemplate: true, isLoading: false });
      console.log("sorry cant load");
    }
  };

  useEffect(()=>{
    if(fetchedServiceData&&
      fetchedGuidesData&&
      fetchedTrainingsData&&
      fetchedPresentationsData&&
      fetchedServicesData&&
      fetchedMultiMediumData&&
      fetchedPOCList){
        setOnInitiateSearchCompleted(true)
      }

  },[fetchedServiceData,
    fetchedGuidesData,
    fetchedTrainingsData,
    fetchedPresentationsData,
    fetchedServicesData,
    fetchedMultiMediumData,
    fetchedPOCList])
  const processGroupFilters = (docsByGroups) => {
    var filterDataParam = filterData;
    docsByGroups.map((value) => {
      value.doclist.docs.map((value) => {
        filterDataParam.push(value);
      });
    });
    setfilterData(filterDataParam);
    // this.setState({ filterData });
  };

  const getopenSurvey = () => {
    setopenSurvey(true);
    // this.setState({ openSurvey: true });
  };

  const processFilters = (Docs) => {
    var filterDataParam = filterData;
    Docs.map((value) => {
      filterDataParam.push(value);
    });
    setfilterData(filterDataParam);
    // this.setState({ filterData });
  };

  const resetResults = () => {
    // window.location.href = window.location.href;
    setsearchTerm("");
    setCustomerID("");
    setAccountID("");
    setserviceData(null);
    setreuseDate(null);
    setPOCList(null);
    sethpseData(null);
    setSKUData(null);
    setCustomerOpportunityData(null);
    setaccount_name(null);
    setfilterData([]);
    setfilters("&fq=-isarchived:True");
    setactiveFilter("");
    setfineSearchValue("");
    setisRefreshText(false);
    setSelectedFilters(null);
    setOnresetResultsCompleted(true);
    setClickedResetResults(true);

    // this.setState(
    //   {
    //     searchTerm: "",
    //     CustomerID: "",
    //     AccountID: "",
    //     serviceData: null,
    //     reuseDate: null,
    //     POCList: null,
    //     hpseData: null,
    //     SKUData: null,
    //     CustomerOpportunityData: null,
    //     account_name: null,
    //     filterData: [],
    //     filters: "&fq=-isarchived:True",
    //     activeFilter: "",
    //     fineSearchValue: "",
    //     isRefreshText: false,
    //     SelectedFilters: null,
    //   },
    // () => {
    //   this.ClearSearch.current.ClearSearch();
    // }
    // );
  };

  useEffect(() => {
    if (onresetResultsCompleted === undefined) {
      return;
    }
    ClearSearch.current.ClearSearch();
  }, [onresetResultsCompleted]);

  const ResetKnowledgeAndRefCapsule = () => {
    setForFilterRefresh(Date.now());
    setfilterData([]);
    setSelectedFilters(null);
    setfilters("&fq=-isarchived:True");
    setreuseDate(null);
    setserviceData(null);
    // this.setState({
    //   ForFilterRefresh: Date.now(),
    //   filterData: [],
    //   SelectedFilters: null,
    //   filters: "&fq=-isarchived:True",
    //   reuseDate: null,
    //   serviceData: null,
    // });
  };

  const ApplyFilter = (
    filtersParam = filters,
    SelectedFiltersParam = SelectedFilters
  ) => {
    setfilters(filtersParam);
    setSelectedFilters(SelectedFiltersParam);

    setOnApplyFilterCompleted(true);

    // this.setState({ filters, SelectedFilters }, () => {
    //   if (this.state.searchTerm !== "") {
    //     this.onSearchFormSubmit(this.state.searchTerm);
    //   }
    // });
  };

  useEffect(() => {
    if (onApplyFilterCompleted === undefined) {
      return;
    }

    if (searchTerm !== "") {
      onSearchFormSubmit(searchTerm);
    }
  }, [onApplyFilterCompleted]);

  const ResetFilters = () => {
    const FilterAppliedEarlier =
      filters !== "&fq=-isarchived:True" ? true : false;
    setForFilterRefresh(Date.now());
    setfilters("&fq=-isarchived:True");
    setSelectedFilters(null);
    setFilterAppliedEarlier(FilterAppliedEarlier);
    setOnResetFiltersCompleted(true);
    // this.setState(
    //   {
    //     ForFilterRefresh: Date.now(),
    //     filters: "&fq=-isarchived:True",
    //     SelectedFilters: null,
    //   },
    //   () => {
    //     if (FilterAppliedEarlier && this.state.searchTerm !== "")
    //       this.onSearchFormSubmit(this.state.searchTerm);
    //   }
    // );
  };
  useEffect(() => {
    if (onResetFiltersCompleted === undefined) {
      return;
    }
    if (FilterAppliedEarlier && searchTerm !== "") {
      onSearchFormSubmit(searchTerm);
    }
  }, [onResetFiltersCompleted]);

  const ClearFilter = (FilterName) => {
    const { ClearArchivedDocs, ClearNDAOnly } = ClearAFilter.current;

    if (ClearAFilter.current && ClearAFilter.current.ClearAFilter) {
      if (
        ClearNDAOnly.current == null &&
        FilterName === "NDAOnly" &&
        SelectedFilters?.IsNDAOnly === true
      ) {
        var filterString =
          '&fq=nda:"True"&fq=ndamails_raw:*' + Cookies.get("mail") + "*";
        var filtersParam = filters;
        // Replace filterString within filters
        filtersParam = filtersParam.replace(filterString, "");
        if (
          SelectedFilters.SelectedShowArchivedDocs == false &&
          SelectedFilters.SelectedGoldCollateral === false
        ) {
          filtersParam = "&fq=-isarchived:True";
        }

        SelectedFilters.IsNDAOnly = false;
        setfilters(filterString);
        setSelectedFilters(SelectedFilters);
        setcheckNDAstatus(true);
        // setClearFilterfiltersParam(filtersParam)
        setonClearFilterCompleted(true);

        // this.setState(
        //   {
        //     filters: filterString,
        //     SelectedFilters: this.state.SelectedFilters,
        //     checkNDAstatus: true,
        //   },
        //   () => {
        //     this.ApplyFilter(filters, this.state.SelectedFilters);
        //   }
        // );
      } else {
        if (
          ClearNDAOnly.current == null &&
          SelectedFilters?.IsNDAOnly === true
        ) {
          var filtersParams = filters;
          var filterStrings =
            '&fq=nda:"True"&fq=ndamails_raw:*' + Cookies.get("mail") + "*";

          // Check if filterStrings is not already present in filters
          if (!filtersParams.includes(filterStrings)) {
            filtersParams += filterStrings;
          }
          setfilters(filtersParam);
          setonClearFilterCompleted(true);

          // this.setState(
          //   {
          //     filters: filters,
          //   },
          //   () => {
          //     this.ApplyFilter(filters, this.state.SelectedFilters);
          //   }
          // );

          ClearAFilter.current.ClearAFilter(FilterName);
        } else {
          ClearAFilter.current.ClearAFilter(FilterName);
        }
      }
    } else {
      sethardClearValue(FilterName);
      // this.setState({ hardClearValue: FilterName });
    }
  };
  useEffect(() => {
    if (onClearFilterCompleted === undefined) {
      return;
    }
    ApplyFilter(filters, SelectedFilters);
  }, [onClearFilterCompleted]);

  const getMappingProfileforRestriction = () => {
    const SelectedURL =
      URLConfig.getURLDeltaAPI() +
      "api/UserProfile/GetMappingProfileforRestriction/" +
      localStorage.getItem("empnumber");
    // "25075739"
    axios.get(SelectedURL).then((res) => {
      console.log(
        "getMappingProfileforRestriction res",
        res,
        Cookies.get("empnumber")
      );
      setsetselectedKnowledgeCategory(
        res.data.filter((data) => {
          return data.capsuleName == "KNOWLEDGE";
        })
      );
      setselectedReuseCategory(
        res.data.filter((data) => {
          return data.capsuleName == "REFERENCE (Re-Use)";
        })
      );
    });
  };

  const onCustomerSearchFormSubmit = (searchTermParam) => {
    // if (this.state.filterData.length == 0){
    // document.getElementById("SearchAutoSuggestionsPrimary").hidden = true;
    // 4th feb 24 added content hidden is true in all places as the new id for searchformredesign is content
    // document.getElementById("content").hidden = true;
    // document.getElementById("searchBtn").hidden = true;
    document.getElementById("practiceProjectDic").hidden = true;
    // }
    setisLoading(true);
    // this.setState({
    //   isLoading: true
    // });
    //searchTerm = encodeURI(searchTerm);
    TrackingServices.LogAccountSearch(
      Cookies.get("empnumber"),
      searchTermParam
    );
    try {
      var hasNumber = /\d/;
      setSurveySearchItem(searchTermParam);
      // this.setState({ SurveySearchItem: searchTerm });
      if (activeFilter && activeFilter === "OPP ID") {
        getAccountID(searchTermParam);
        // Below line is commented as it's not required in the new home page design
        // date-21/12/2023
        // this.MoveSearchElements();
      } else if (activeFilter && activeFilter === "WBS ID") {
        getOpportunityIDByWbsID(searchTermParam);
        // Below line is commented as it's not required in the new home page design
        // date-21/12/2023
        // this.MoveSearchElements();
      } else if (activeFilter && activeFilter === "ST ID") {
        const accID = "Account.Account_ST_ID__c%20=%20%27" + searchTermParam;
        setAccountID(accID);
        // this.setState({ AccountID: accID });
        getOpportunityDataByAccountIDSTID(accID);
      } else if (activeFilter && activeFilter === "PR ID") {
        getAccountIDByPRID(searchTermParam);
      } else if (activeFilter && activeFilter === "Service Agreement Id") {
        getgetAccountIDByServiceAgreementId(searchTermParam);
      } else if (
        (activeFilter && activeFilter === "Serial ID") ||
        (((activeFilter == "" && searchTermParam.length === 8) ||
          searchTermParam.length === 10 ||
          searchTermParam.length === 12) &&
          searchTermParam.includes("-") === false &&
          !isNumber(searchTermParam) &&
          hasNumber.test(searchTermParam) &&
          searchTermParam.includes(" ") == false)
      ) {
        const serialidurl =
          URLConfig.getURLDeltaAPI() + "SearchSerialNumber/" + searchTermParam;

        axios(serialidurl).then((res) => {
          if (res.data.account_ST_ID) {
            const rawacid = res.data.account_ST_ID;
            const AccountIDParam =
              "Account.Account_ST_ID__c%20=%20%27" + res.data.account_ST_ID;
            const ProductName = res.data.product_Name;
            setAccountID(AccountIDParam);
            setProductName(ProductName === "null" ? "" : ProductName);
            setsearchTerm(ProductName);
            setshowHeader(true);
            setRawAcid(rawacid);
            setonCustomerSearchFormSubmitCompleted(true);

            // this.setState(
            //   {
            //     AccountID: AccountID,
            //     ProductName: ProductName === "null" ? "" : ProductName,
            //     searchTerm: ProductName,
            //     showHeader: true,
            //     // parentAccountStID: 474737,
            //   },
            //   () => this.getOpportunityDataByAccountIDSTID(AccountID),
            //   this.getparentSTID(rawacid),
            //   this.onSearchFormSubmit(ProductName),
            //    // Below line is commented as it's not required in the new home page design
            //   // date-21/12/2023
            //   // this.MoveSearchElements()
            // );
          } else {
            toast.error("Account Details not Available", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              isLoading: false,
            });
          }
        });
      } else if (activeFilter && activeFilter === "Account Name") {
        getAccountIdByAccountName(searchTermParam);
      }
    } catch (error) {
      setshowOutageTemplate(true);
      setisLoading(false);
      // this.setState({ showOutageTemplate: true, isLoading: false });
    }
  };

  useEffect(() => {
    if (onCustomerSearchFormSubmitCompleted === undefined) {
      return;
    }
    getOpportunityDataByAccountIDSTID(AccountID);
    getparentSTID(rawAcid);
    onSearchFormSubmit(ProductName);
  }, [onCustomerSearchFormSubmitCompleted]);

  const getAccountID = (OpportunityID) => {
    setisLoading(true);
    setshowHeader(true);
    setCustomerID(OpportunityID);
    // this.setState({

    //   isLoading: true,
    //   showHeader: true,
    // })
    // this.setState({ CustomerID: OpportunityID });
    const serviceUrl = URLConfig.getURLActIDOPP(OpportunityID);

    axios(serviceUrl).then((res) => {
      if (res?.data?.response[0]?.IsPrivate === true) {
        toast.error(
          "Entered customer identifier marked for NDA. Unable to load customer capsule",
          {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            type: "info",
            className: "ndatoast",
          }
        );
      } else {
        if (res?.data?.response?.length) {
          const AccountIDParam =
            "Account.Account_ST_ID__c%20=%20%27" +
            res.data.response[0].Account_Account_ST_ID__c;
          const parentAccountStID =
            res.data.response[0].Account_Top_Parent_ST_ID__c == null
              ? res.data.response[0].Account_Account_ST_ID__c
              : res.data.response[0].Account_Top_Parent_ST_ID__c;
          setparentAccountStID(parentAccountStID);
          setAccountID(AccountIDParam);

          // this.setState({ parentAccountStID: parentAccountStID });
          // this.setState({ AccountID: AccountID });
          getOpportunityDataByAccountID(AccountIDParam);
          getAccountDetails(res.data.response[0]);
        } else {
          setAccountID("");
          setisLoading(false);
          setisRefreshText(false);
          // this.setState({ AccountID: "" });
          // this.setState({
          //   isLoading: false,
          //   isRefreshText: false,
          // });
          toast.error(
            "Account ST ID unavailable. Unable to load customer capsule",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  const getOpportunityIDByWbsID = (wbsID) => {
    const serviceURL = URLConfig.getURLOpportunityIDByWbsID(wbsID);
    setisLoading(true);
    setisRefreshText(false);
    setshowHeader(true);
    // this.setState({
    //   isLoading: true,
    //   isRefreshText: false,
    //   showHeader: true,
    // });

    //Timer for long run

    // axios(serviceURL)
    URLConfig.getnewsolrBaseURL(serviceURL)
    .then((res) => {
      if (res?.data?.response?.docs?.length) {
        const opportunity_id = res.data.response.docs[0].opportunity_id;
        getAccountID(opportunity_id);
      } else {
        setAccountID("");
        setisLoading(false);
        isRefreshText(false);

        // this.setState({
        //   AccountID: "",
        //   isLoading: false,
        //   isRefreshText: false,
        // });
      }
    });
  };

  const getAccountDetails = (accountDetails) => {
    setisLoading(true);
    setisRefreshText(false);
    // this.setState({
    //   isLoading: true,
    //   isRefreshText: false,
    // });
    setaccount_name(accountDetails.Account_Account_ST_Name__c);
    setgetAccountDetailsCompleted(true);
    // this.setState(
    //   { account_name: accountDetails.Account_Account_ST_Name__c },
    //   () => {
    //     this.closeLoaderHandler();
    //   }
    // );
  };

  useEffect(() => {
    if (getAccountDetailsCompleted === undefined) {
      return;
    }
    closeLoaderHandler();
  }, [getAccountDetailsCompleted]);

  const closeLoaderHandler = () => {
    setisLoading(false);
    setisRefreshText(false);
    // this.setState({
    //   isLoading: false,
    //   isRefreshText: false,
    // });
  };
  const getOpportunityDataByAccountID = (AccountID) => {
    const serviceUrl = URLConfig.getUrlOppDataByActID(AccountID);
    axios(serviceUrl).then((res) => {
      if (res.data.response.length) {
        document.getElementsByTagName("body")[0].click();
        const CustomerOpportunityData = res.data.response;
        setCustomerOpportunityData(CustomerOpportunityData);
        setisLoading(false);
        setisRefreshText(false);
        // this.setState({
        //   CustomerOpportunityData: CustomerOpportunityData,
        //   isLoading: false,
        //   isRefreshText: false,
        // });
      } else {
        setisLoading(false);
        setisRefreshText(false);
        // this.setState({
        //   isLoading: false,
        //   isRefreshText: false,
        // });
      }
    });
  };

  const getOpportunityDataByAccountIDSTID = (AccountID) => {
    setisLoading(true);
    setisRefreshText(false);
    setshowHeader(true);
    // this.setState({
    //   isLoading: true,
    //   isRefreshText: false,
    //   showHeader: true,
    // });
    const serviceUrl = URLConfig.getUrlOppDataByActID(AccountID);
    axios(serviceUrl).then((res) => {
      if (res.data.response.length) {
        // Below line is commented as it's not required in the new home page design
        // date-03/01/2024
        // this.MoveSearchElements();
        // document.getElementsByTagName("body")[0].click();
        const CustomerOpportunityData = res.data.response;
        CustomerOpportunityData[0].Account_Account_ST_Name__c &&
          setaccount_name(
            CustomerOpportunityData[0].Account_Account_ST_Name__c
          );
        // this.setState({
        //   account_name: CustomerOpportunityData[0].Account_Account_ST_Name__c,
        // });
        console.log(
          CustomerOpportunityData[0].Account_Account_ST_Name__c,
          "<><><><>"
        );
        setTimeout(() => {
          setSurveySearchResult(true);
          // this.setState({ SurveySearchResult: true });
        }, 4000);

        setCustomerOpportunityData(CustomerOpportunityData);
        setisLoading(false);
        setisRefreshText(false);

        // this.setState({
        //   CustomerOpportunityData: CustomerOpportunityData,
        //   isLoading: false,
        //   isRefreshText: false,
        // });
      } else {
        setAccountID("");
        setisLoading(false);
        setisRefreshText(false);
        setCustomerOpportunityData(null);
        setSurveySearchResult(false);
        setshowHeader(true);
        // this.setState({
        //   AccountID: "",
        //   isLoading: false,
        //   isRefreshText: false,
        //   CustomerOpportunityData: null,
        //   SurveySearchResult: false,
        //   showHeader: true,
        // });

        toast.error("Customer Details not Available", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    });
    navigateDocumentSearchResult();
  };

  const getAccountIDByPRID = (prID) => {
    setisLoading(true);
    setisRefreshText(false);
    setshowHeader(true);
    // this.setState({
    //   isLoading: true,
    //   isRefreshText: false,
    //   showHeader: true,
    // });
    const serviceURL = URLConfig.getUrlOppDataByPRID(prID);
    axios(URLConfig.ApplyAuth(serviceURL)).then((res) => {
      if (res?.data?.response?.length) {
        const accountID = res.data.response[0].pse__Account__r_Account_ST_ID__c;
        if (accountID) {
          const accID = "Account.Account_ST_ID__c%20=%20%27" + accountID;
          setAccountID(accID);
          // this.setState({ AccountID: accID });
          getOpportunityDataByAccountIDSTID(accID);
        } else {
          setisLoading(false);
          setisRefreshText(false);
          // this.setState({
          //   isLoading: false,
          //   isRefreshText: false,
          // });
          toast.error(
            "Account ST ID unavailable for the entered PR ID. Unable to load the customer capsule ",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } else {
        setisLoading(false);
        setisRefreshText(false);
        // this.setState({
        //   isLoading: false,
        //   isRefreshText: false,
        // });
        toast.error("Please enter valid PR ID", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    });
  };

  const isNumber = (str) => {
    var reg = new RegExp("^[0-9]+$");
    return reg.test(str);
  };

  const getparentSTID = (rawacid) => {
    const pstidURL =
      "https://hpedelta.com:5003/services/data/v38.0/sobjects/query2?q=select%20Asset_Serial_Number__c,%20Account.Name,%20Account.Account_ST_ID__c,%20Account.Top_Parent_ST_ID__c%20from%20Case%20where%20Account.Account_ST_ID__c%20=%20%27" +
      rawacid +
      "%27%20limit%201";
    const config = URLConfig.ApplyAuth(pstidURL);
    axios(config).then((response) => {
      if (response?.data?.response?.length) {
        const parentAccountStID =
          response.data.response[0].Account_Top_Parent_ST_ID__c == null
            ? response.data.response[0].Account_Account_ST_ID__c
            : response.data.response[0].Account_Top_Parent_ST_ID__c;
        setparentAccountStID(parentAccountStID);
        setshowHeader(true);
        // this.setState({
        //   parentAccountStID: parentAccountStID,
        //   showHeader: true,
        // });
      }
    });
  };

  const getAccountIdByAccountName = (searchTerm) => {
    setisLoading(true);
    setisRefreshText(false);
    // this.setState({
    //   isLoading: true,
    //   isRefreshText: false,
    // });
    const accountName = encodeURIComponent(searchTerm);

    const serviceURL = URLConfig.getUrlAccountIdByAccountName(accountName);
    setaccount_name(searchTerm);
    // this.setState({ account_name: searchTerm });
    // axios(URLConfig.ApplyAuth(serviceURL))
    URLConfig.getnewsolrBaseURL(serviceURL)
    .then((res) => {
      if (res.data.response.docs.length) {
        const accountID = res.data.response.docs[0].Account_ST_ID;
        const accID = "Account.Account_ST_ID__c%20=%20%27" + accountID;
        setAccountID(accID);
        setGetAccountIdByAccountNameAccountID(accountID);
        setongetAccountIdByAccountNameCompleted(true);

        // this.setState({ AccountID: accID }, () =>
        //   this.getparentSTID(accountID)
        // );
        // this.getOpportunityDataByAccountIDSTID(accID);
        getOpportunityDataByAccountIDSTID(accID);
      } else {
        setAccountID("");
        setisLoading(false);
        setisRefreshText(false);
        setCustomerOpportunityData(null);
        setSurveySearchResult(false);
        setshowHeader(true);
        // this.setState({
        //   AccountID: "",
        //   isLoading: false,
        //   isRefreshText: false,
        //   CustomerOpportunityData: null,
        //   SurveySearchResult: false,
        //   showHeader: true,
        // });
        // document.getElementById("SearchAutoSuggestionsPrimary").hidden = false;
        toast.error("Customer Details not Available", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    });
  };
  useEffect(() => {
    if (ongetAccountIdByAccountNameCompleted === undefined) {
      return;
    }
    getparentSTID(getAccountIdByAccountNameAccountID);
  }, [ongetAccountIdByAccountNameCompleted]);

  const UpdateUserCategoryMapping = () => {
    getMappingProfileforRestriction();
    if (searchTerm != "") {
      onSearchFormSubmit(searchTerm);
    }
  };

  const UpdateRoles = (Roles) => {
    var DefaultRoles = Cookies.get("roles")?.split(",");
    DefaultRoles = DefaultRoles.filter(
      (x) =>
        x === "Admin" ||
        x === "Analyst" ||
        x === "Process" ||
        x === "Specialist"
    );
    Roles = Roles.concat(DefaultRoles);
    if (Roles.length > 0) Cookies.set("roles", Roles.join(","));
    else Cookies.set("roles", "Guest");
    //Re-initiate Search
    if (searchTerm !== "") onSearchFormSubmit(searchTerm);
  };

  const closeSurvey = () => {
    setopenSurvey(false);
    // this.setState({ openSurvey: false });
  };

  const getOpportunityData = (props) => {
    setisLoading(true);
    setisRefreshText(false);
    // this.setState({ isLoading: true, isRefreshText: false });
    // if (props == "") {
    //   this.getOpportunityDataByAccountIDSTID(this.state.AccountID);
    // }
    if (props !== "") {
      var stid = AccountID.replace("Account.Account_ST_ID__c%20=%20%27", "");
      const OPPURL =
        "https://hpedelta.com:5003/services/data/v38.0/sobjects/query2?q=Select%20Account.Account_ST_Name__c,%20Opportunity_ID__c,%20convertCurrency(Amount),%20IsWon,%20IsClosed,%20Opportunity_Sales_Stage__c%20,%20Status__c%20from%20Opportunity%20WHERE%20Opportunity_Sales_Stage__c !='HPE Not Pursued' AND%20Account.Account_ST_ID__c=%27" +
        stid +
        "%27 AND IsPrivate=false %20 AND %20" +
        props;

      const config = URLConfig.ApplyAuth(OPPURL);
      axios(config).then((res) => {
        if (res.data) {
          if (res.data.response.length) {
            // Below line is commented as it's not required in the new home page design
            // date-03/01/2024
            // this.MoveSearchElements();
            document.getElementsByTagName("body")[0].click();
            const CustomerOpportunityData = res.data.response;
            CustomerOpportunityData[0].Account_Account_ST_Name__c &&
              setaccount_name(
                CustomerOpportunityData[0].Account_Account_ST_Name__c
              );
            // this.setState({
            //   account_name:
            //     CustomerOpportunityData[0].Account_Account_ST_Name__c,
            // });
            setCustomerOpportunityData(CustomerOpportunityData);
            setisLoading(false);
            setisRefreshText(false);
            // this.setState({
            //   CustomerOpportunityData: CustomerOpportunityData,
            //   isLoading: false,
            //   isRefreshText: false,
            // });
          } else {
            setisLoading(false);
            setisRefreshText(false);
            setnoCustomerDetails(true);

            // this.setState({
            //   isLoading: false,
            //   isRefreshText: false,
            //   noCustomerDetails: true,
            // });
            // toast.error(
            //   "Customer Details for the date entered are not Available",
            //   {
            //     position: "top-right",
            //     autoClose: 4000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //   }
            // );
          }
        }
      });
    }
  };

  const ResetCustomerCapsule = () => {
    if (AccountID !== "") {
      setAccountID("");
      // this.setState({ AccountID: "" });
    }
  };

  const alertYesClicked = () => {
    setnoCustomerDetails(false);
    setCustomerOpportunityData([]);
    setAccountID("");
  };

  const alertResetClicked = () => {
    setnoCustomerDetails(false);
    setdoResetFilters((prevdoResetFilters) => {
      return !prevdoResetFilters;
    });
  };

  const resetPopup = () => {
    setisRefreshText(true);
    // this.setState({ isRefreshText: true });
  };

  const getgetAccountIDByServiceAgreementId = (searchTerm) => {
    const URL =
      "https://hpedelta.com:8983/solr/service_credit/select?fl=SAID,Account_ST_ID,Account_ST_Name,AccountName,Top_Parent_ST_ID,Top_Parent_ST_Name&fq=SAID:%22" +
      searchTerm +
      "%22&indent=on&q=*:*&rows=1&wt=json";

    axios(URL).then((res) => {
      if (res?.data?.response?.docs?.length) {
        const AccountIDParam =
          "Account.Account_ST_ID__c%20=%20%27" +
          res?.data?.response?.docs[0].Account_ST_ID;
        if (AccountIDParam) {
          setAccountID(AccountIDParam);
          // this.setState({ AccountID });
          getOpportunityDataByAccountIDSTID(AccountIDParam);
        }
      } else {
        setshowHeader(true);
        // this.setState({showHeader:true});
        toast.error("Customer Details not Available", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    });
  };

  const setActiveFilter = (selectedFilter, searchIdValue) => {
    if(searchIdValue !== fineSearchValue)
      {
        setAccountID("");
        setactiveFilter(selectedFilter);
        setfineSearchValue(searchIdValue);
      }
    // setactiveFilter(selectedFilter);
    // setfineSearchValue(searchIdValue);

    // this.setState({
    //   activeFilter: selectedFilter,
    //   fineSearchValue: searchIdValue,
    // });
  };

  const resetRefinedSearch = () => {
    setCustomerID("");
    setactiveFilter("");
    setfineSearchValue("");
    // this.setState({
    //   CustomerID: "",
    //   // AccountID: "",
    //   // CustomerOpportunityData: null,
    //   // account_name: null,
    //   activeFilter: "",
    //   fineSearchValue: "",
    // });
  };

  const logMyContribution = () => {
    // console.log(Cookies.get("empnumber"), "LogMyContribution");
    TrackingServices.LogMyContributionlinkClick(Cookies.get("empnumber"), true);
  };

  const showFeedback = () => {
    console.log(Cookies.get("empnumber"), "Feedback");
    hidePractice();
    setshowFeedbackform((prevShowFeedbackform) => {
      return !prevShowFeedbackform;
    });
    // this.setState({
    //   showFeedbackform: !this.state.showFeedbackform,
    // });
    TrackingServices.LogFeedbacklinkClick(Cookies.get("empnumber"), true);
  };

  const hidePractice = () => {
    var srcElement = document.getElementById("practiceProjectDic");
    if (srcElement != null) {
      if (srcElement.style.display == "block") {
        srcElement.style.display = "none";
      }
    }
  };

  const onFeedbackFormClose = () => {
    setshowFeedbackform((prevShowFeedbackform) => {
      return !prevShowFeedbackform;
    });
    // this.setState({
    //   showFeedbackform: !this.state.showFeedbackform,
    // });
    toast.success("Thank you for submitting your feedback", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const closeFeedback = () => {
    setshowFeedbackform((prevShowFeedbackform) => {
      return !prevShowFeedbackform;
    });
    // this.setState({showFeedbackform : !this.state.showFeedbackform})
  };

  const navigateDocumentSearchResult = () => {
    navigate("/search");
  };
  const showHideDivProjects = (ele) => {
    var srcElement = document.getElementById(ele);
    if (srcElement != null) {
      if (srcElement.style.display == "block") {
        srcElement.style.display = "none";
      } else {
        srcElement.style.display = "block";
      }
      return false;
    }
  };

  const triggeredRefineSearch = () => {
    setTriggerRefineSearch(true);
  };

  const useCustomPrompt = () => {
    setConfirmationPopup(true);
    setTitle("Reset?");
    setMessage(
      "This will reset the current search so that you can start fresh.Do you want to continue?"
    );
    setButtons({
      cancel: {
        label: "No Thanks",
        callback: () => {
          setConfirmationPopup(false);
        },
      },
      confirm: {
        label: "Reset",
        callback: () => {
          resetResults();
          resetPopup();
          setConfirmationPopup(false);
        },
      },
    });
  };

  // useEffect(()=>{
  //   console.log("invokeInitiateSearch")
  //   if(invokeInitiateSearch){
  //     console.log("invokeInitiateSearch if")
  //     InitiateSearch(searchTerm)
  //   }

  // },[invokeInitiateSearch])

  // const archiveActiveValue = (activearchivevalue) => {
  //   // this.setState(
  //   //   {
  //   //     archiveActive: activearchivevalue,
  //   //   },
  //   //   () => {}
  //   // );
  //   setArchiveActive(activearchivevalue);
  // };

  const showHideDiv = (ele) => {
    var srcElement = document.getElementById(ele);
    if (srcElement != null) {
      if (srcElement.style.display == "block") {
        srcElement.style.display = "none";
      } else {
        srcElement.style.display = "block";
      }
      return false;
    }
  };


  const archiveActiveValue = (activearchivevalue) => {
    setarchiveActive(activearchivevalue);
    // this.setState(
    //   {
    //     archiveActive: activearchivevalue,
    //   },
    //   () => {}
    // );
  };

  return {
    currentHomeImageMapper: currentHomeImageMapper,
    setCurrentHomeImageMapper: setCurrentHomeImageMapper,
    MasterData: MasterData,
    getAllMasterData: getAllMasterData,
    showDocAnalysisModule: showDocAnalysisModule,
    showDocDepo: showDocDepo,
    showDocAnalysys: showDocAnalysys,
    toggleDocDepo: toggleDocDepo,
    toggleDocDepoFun: toggleDocDepoFun,
    PursuitDoc: PursuitDoc,
    processPursuitDocument: processPursuitDocument,
    key: key,
    refreshDocDepo: refreshDocDepo,
    homeredirect: homeredirect,
    SelectedFilters: SelectedFilters,
    serviceData: serviceData,
    POCList: POCList,
    hpseData: hpseData,
    PresentationsData: PresentationsData,
    setselectedKnowledgeCategory: setselectedKnowledgeCategory,
    GuidesData: GuidesData,
    acceleratorData: acceleratorData,
    ServicesData: ServicesData,
    SKUData: SKUData,
    searchTerm: searchTerm,
    MultiMediumData: MultiMediumData,
    TrainingsData: TrainingsData,
    filters: filters,
    maxScore: maxScore,
    closeSurvey: closeSurvey,
    checkSelectedCategory: checkSelectedCategory,
    filterData: filterData,
    alertYesClicked: alertYesClicked,
    alertResetClicked: alertResetClicked,
    isRefreshText: isRefreshText,
    showHeader: showHeader,
    activeFilter: activeFilter,
    fineSearchValue: fineSearchValue,
    onCustomerSearchFormSubmit: onCustomerSearchFormSubmit,
    setActiveFilter: setActiveFilter,
    ResetCustomerCapsule: ResetCustomerCapsule,
    ResetKnowledgeAndRefCapsule: ResetKnowledgeAndRefCapsule,
    resetRefinedSearch: resetRefinedSearch,
    ClearSearch: ClearSearch,
    CustomerOpportunityData: CustomerOpportunityData,
    ClearFilter: ClearFilter,
    ApplyFilter: ApplyFilter,
    lastSearchHistorySearch: lastSearchHistorySearch,
    ForFilterRefresh: ForFilterRefresh,
    ResetFilters: ResetFilters,
    ClearAFilter: ClearAFilter,
    hardClearValue: hardClearValue,
    reuseDate: reuseDate,
    ClearArchivedDocs: ClearArchivedDocs,
    selectedReuseCategory: selectedReuseCategory,
    insightData: insightData,
    TemplateSelected: TemplateSelected,
    account_name: account_name,
    getOpportunityData: getOpportunityData,
    doResetFilters: doResetFilters,
    getopenSurvey: getopenSurvey,
    logMyContribution: logMyContribution,
    showFeedback: showFeedback,
    showFeedbackform: showFeedbackform,
    onFeedbackFormClose: onFeedbackFormClose,
    closeFeedback: closeFeedback,
    isLoading: isLoading,
    noCustomerDetails: noCustomerDetails,
    getMappingProfileforRestriction: getMappingProfileforRestriction,
    onSearchFormSubmit: onSearchFormSubmit,
    AccountID: AccountID,
    TrackingServices: TrackingServices,
    SurveySearchItem: SurveySearchItem,
    SurveySearchResult: SurveySearchResult,
    TemplatesData: TemplatesData,
    TotalCount: TotalCount,
    showOutageTemplate: showOutageTemplate,
    openSurvey: openSurvey,
    onSearchFormSubmitCompleted: onSearchFormSubmitCompleted,
    CustomerID: CustomerID,
    onApplyFilterCompleted: onApplyFilterCompleted,
    onResetFiltersCompleted: onResetFiltersCompleted,
    FilterAppliedEarlier: FilterAppliedEarlier,
    checkNDAstatus: checkNDAstatus,
    onClearFilterCompleted: onClearFilterCompleted,
    clearFilterfiltersParam: clearFilterfiltersParam,
    ProductName: ProductName,
    onCustomerSearchFormSubmitCompleted: onCustomerSearchFormSubmitCompleted,
    rawAcid: rawAcid,
    parentAccountStID: parentAccountStID,
    getAccountDetailsCompleted: getAccountDetailsCompleted,
    ongetAccountIdByAccountNameCompleted: ongetAccountIdByAccountNameCompleted,
    getAccountIdByAccountNameAccountID: getAccountIdByAccountNameAccountID,
    CheckEneteredLanguage: CheckEneteredLanguage,
    InitiateSearch: InitiateSearch,
    processGroupFilters: processGroupFilters,
    processFilters: processFilters,
    resetResults: resetResults,
    getAccountID: getAccountID,
    getOpportunityIDByWbsID: getOpportunityIDByWbsID,
    getAccountDetails: getAccountDetails,
    closeLoaderHandler: closeLoaderHandler,
    getOpportunityDataByAccountID: getOpportunityDataByAccountID,
    getOpportunityDataByAccountIDSTID: getOpportunityDataByAccountIDSTID,
    getAccountIDByPRID: getAccountIDByPRID,
    isNumber: isNumber,
    getparentSTID: getparentSTID,
    getAccountIdByAccountName: getAccountIdByAccountName,
    UpdateUserCategoryMapping: UpdateUserCategoryMapping,
    UpdateRoles: UpdateRoles,
    resetPopup: resetPopup,
    getgetAccountIDByServiceAgreementId: getgetAccountIDByServiceAgreementId,
    hidePractice: hidePractice,
    navigateDocumentSearchResult: navigateDocumentSearchResult,
    showHideDivProjects: showHideDivProjects,
    triggeredRefineSearch: triggeredRefineSearch,
    isDeltaUser: isDeltaUser,
    useCustomPrompt: useCustomPrompt,
    confirmationPopup: confirmationPopup,
    setConfirmationPopup: setConfirmationPopup,
    message: message,
    setMessage: setMessage,
    title: title,
    setTitle: setTitle,
    buttons: buttons,
    setButtons: setButtons,
    showHideDiv: showHideDiv,
    archiveActive: archiveActive,
    archiveActiveValue: archiveActiveValue,
    showLoader: showLoader,
    ChannelPartnerSearchTerm: ChannelPartnerSearchTerm,
    fetchedServiceData: fetchedServiceData,
    fetchedGuidesData: fetchedGuidesData,
    fetchedTrainingsData: fetchedTrainingsData,
    fetchedPresentationsData: fetchedPresentationsData,
    fetchedServicesData: fetchedServicesData,
    fetchedMultiMediumData: fetchedMultiMediumData,
    fetchedSKUData: fetchedSKUData,
    fetchedPOCList: fetchedPOCList,
    fetchedAcceleratorData: fetchedAcceleratorData,
    onInitiateSearchCompleted:onInitiateSearchCompleted,
    setShowLoader:setShowLoader,
  };

};

export default DeltaHomeStates;
